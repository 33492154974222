import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {
    Box,
    Button,
    Typography,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Checkbox,
    Card,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { Alert } from '@mui/material';
import axios from "../../../utils/axios";

const validationShape = {
    subscriberType: Yup.string()
};

function BulkUploadOptions({
                               enquiryType,
                               stepName,
                               enquiry,
                               onBack,
                               onNext,
                               onChangeEnquiry,
                               onChangeSubscriberType
                           }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: enquiry,
        validationSchema: Yup.object().shape(validationShape),
        onSubmit: (values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting,
        }) => {
            if(values.subscriberType == '') {
                values.subscriberType = undefined;
            }

            axios.post('/web/ui/bulkUser/updateBulkUserEnquiry', values).then(response => {
                if(response.data) {
                    setStatus({success: true});
                    //setSubmitting(false);
                    if (onChangeEnquiry) {
                        onChangeEnquiry(values);
                    }
                    if (onNext) {
                        onNext();
                    }

                    if(onChangeSubscriberType) {
                        onChangeSubscriberType(values.subscriberType);
                    }
                } else {
                    setErrors({submit: 'Error contact details'});
                    setStatus({success: false});
                    setSubmitting(false);
                }
            }).catch(error => {
                setErrors({submit: error.message});
                setStatus({success: false});
                setSubmitting(false);
            });
        }
    });

    return (
        <Card>
            <CardHeader title={enquiryType.title + " - " + stepName} titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
            <Divider/>
            <CardContent>
                {isAlertVisible && formik.errors.schoolInfo != undefined && (
                    <Box mb={3}>
                        <Alert
                            onClose={() => setAlertVisible(false)}
                            severity="info"
                        >
                            {formik.errors.schoolInfo}
                        </Alert>
                    </Box>
                )}
                {formik.isSubmitting || isLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        my={5}
                    >
                        <CircularProgress/>
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            mx="auto"
                        >
                            <Box mt={1}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Please let us know what you would like to do.
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Who would you like to create accounts for?
                                </Typography>
                                <FormControl variant="outlined" style={{minWidth: 120}}>
                                    <RadioGroup row aria-label="subscriberType" name="subscriberType" value={formik.values.subscriberType || ''} onChange={formik.handleChange}>
                                        <FormControlLabel value="STUDENT" control={<Radio />} label="Students" />
                                        <FormControlLabel value="TEACHER" control={<Radio />} label="Teachers" />
                                        {true && (
                                            <FormControlLabel value="" control={<Radio />} label="Students and Teachers" />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                mt={2}
                                ml={-1}
                            >
                                <Checkbox
                                    checked={formik.values.trial}
                                    name="trial"
                                    onChange={formik.handleChange}
                                />
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Is this request for a trial?
                                </Typography>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                mt={2}
                                ml={-1}
                            >
                                <Checkbox
                                    checked={formik.values.clearClasses}
                                    name="clearClasses"
                                    onChange={formik.handleChange}
                                />
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Would you like us to clear out your existing classes?
                                </Typography>
                            </Box>
                            {enquiry.importUserType == 'BOOKSELLER' && enquiry.booksellerSchoolId == 239843 && (
                                <Box alignItems="center" display="flex" mt={2} ml={-1}>
                                    <Checkbox
                                        checked={formik.values.sso}
                                        name="sso"
                                        onChange={formik.handleChange}
                                    />
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Enable Single Sign on
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box
                            mt={6}
                            display="flex"
                        >
                            <Button
                                onClick={() => {setLoading(true); onBack();}}
                                size="large"
                                color="secondary"
                                variant="contained"
                            >
                                Previous
                            </Button>
                            <Box flexGrow={1}/>
                            <Button
                                color="secondary"
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="contained"
                                size="large"
                            >
                                Next
                            </Button>
                        </Box>
                    </form>
                )}
            </CardContent>
        </Card>
    );
}

BulkUploadOptions.propTypes = {
    enquiryType: PropTypes.object,
    stepName: PropTypes.string,
    enquiry: PropTypes.object,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onChangeEnquiry: PropTypes.func
};

export default BulkUploadOptions;
