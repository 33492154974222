import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import Page from '../../components/Page';
import { useTheme } from '@mui/material/styles';

function Error404View() {
  const theme = useTheme();
  const styles = {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
      paddingTop: 80,
      paddingBottom: 80
    },
    image: {
      maxWidth: '100%',
      width: 560,
      maxHeight: 300,
      height: 'auto'
    }
  };
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Page
      style={{...styles.root}}
      title="404: Not found"
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          404: The page you are looking for isn’t here
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          color="textSecondary"
        >
          You either tried some shady route or you
          came here by mistake. Whichever it is, try using the navigation.
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="Under development"
            style={{...styles.image}}
            src="/serviceDesk/static/images/undraw_page_not_found_su7k.svg"
          />
        </Box>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <Button
            color="secondary"
            component={RouterLink}
            to="/"
            variant="outlined"
          >
            Back to home
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default Error404View;
