import React, {useContext} from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserContext from "../context/UserContext";
import authService from "../services/authService";

function AuthGuard({children}) {
    const {user, saveUser} = useContext(UserContext);

    /*if (!user || !user.loggedIn) {
        return <Navigate to="/login" />;
    }*/

    let validAuth = false;
    if (!user || (!user.loggedIn && !user.publicSession)) {
        authService.initServiceDeskSession().then(userSession => {
            saveUser(userSession);
            validAuth = true;
        }).catch(error => {
            console.log('Error initialising service desk session', error);
        });
    }

    if(!validAuth) {
        <Navigate to="/sessionExpired" />
    }

    return children;

}

AuthGuard.propTypes = {
    children: PropTypes.any
};

export default AuthGuard;
