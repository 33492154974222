import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';

function BulkUserTable({users}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    return (
        <Box>
            <TableContainer>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow key="header">
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Class</TableCell>
                            <TableCell>Subject</TableCell>
                            <TableCell>Year level</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((bulkUser) => (
                                (bulkUser.productNames) ? (
                                    <Fragment key={bulkUser.bulkUserId}>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                                                {bulkUser.firstName}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                                                {bulkUser.lastName}
                                            </TableCell>
                                            <TableCell style={{borderBottom: 'none'}}>{bulkUser.email}</TableCell>
                                            <TableCell style={{borderBottom: 'none'}}>{bulkUser.subscriberTypeDescription}</TableCell>
                                            <TableCell style={{borderBottom: 'none'}}>{bulkUser.classNames}</TableCell>
                                            <TableCell style={{borderBottom: 'none'}}>{bulkUser.subject}</TableCell>
                                            <TableCell style={{borderBottom: 'none'}}>{bulkUser.schoolYear}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={7} style={{borderTop: 'none'}}>
                                                <span style={{fontWeight: 'bold'}}>Resources:</span> {bulkUser.productNames}
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                ) : (
                                    <TableRow key={bulkUser.bulkUserId}>
                                        <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                                            {bulkUser.firstName}
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{borderBottom: 'none'}}>
                                            {bulkUser.lastName}
                                        </TableCell>
                                        <TableCell>{bulkUser.email}</TableCell>
                                        <TableCell>{bulkUser.subscriberTypeDescription}</TableCell>
                                        <TableCell>{bulkUser.classNames}</TableCell>
                                        <TableCell>{bulkUser.subject}</TableCell>
                                        <TableCell>{bulkUser.schoolYear}</TableCell>
                                    </TableRow>
                                )
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage="Rows per page"
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

BulkUserTable.propTypes = {
    users: PropTypes.array
};

export default BulkUserTable;
