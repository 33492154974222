import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Typography,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Card,
} from '@mui/material';
import { Alert } from '@mui/material';
import axios from "../../../utils/axios";
import BulkUserTable from "./BulkUserTable";

function ReviewUploadFile({
                              enquiryType,
                              fileSubscriberType,
                              stepName,
                              enquiry,
                              onBack,
                              onNext,
                              onChangeEnquiry,
                              nextStepFinal
                          }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState({});
    const [validBulkUsers, setValidBulkUsers] = useState([]);
    const [invalidBulkUsers, setInvalidBulkUsers] = useState([]);


    const handleUpdateEnquiryTest = (e) => {
        enquiry.enquiryText = e.target.value;
        onChangeEnquiry(enquiry);
    }

    const handleCreateBulkUserUpdateTicket = () => {
        if(!nextStepFinal) {
            if (onNext) {
                onNext();
            }
            return;
        }

        setSubmitting(true);

        axios.post('/web/ui/bulkUser/createBulkUserUpdateTicket', enquiry)
            .then(response => {
                if(response.data) {
                    setStatus({success: true});
                    if (onNext) {
                        onNext();
                    }
                } else {
                    setErrors({submit: 'Error sending accounts'});
                    setAlertVisible(true);
                    setStatus({success: false});
                    setSubmitting(false);
                }
            }).catch(error => {
                setErrors({submit: error.message});
                setAlertVisible(true);
                setStatus({success: false});
                setSubmitting(false);
            });
    }

    const findValidBulkUsers = (bulkUserUpdateId, subscriberType) => {
        axios.get('/web/ui/bulkUser/findBulkUsers', {params: {bulkUserUpdateId: bulkUserUpdateId, subscriberType: subscriberType, valid: true}})
            .then(response => {
                setValidBulkUsers(response.data);
            }).catch(error => {
                setValidBulkUsers([]);
            });
    }

    const findInvalidBulkUsers = (bulkUserUpdateId, subscriberType) => {
        axios.get('/web/ui/bulkUser/findBulkUsers', {params: {bulkUserUpdateId: bulkUserUpdateId, subscriberType: subscriberType, valid: false}})
            .then(response => {
                setInvalidBulkUsers(response.data);
            }).catch(error => {
            setInvalidBulkUsers([]);
        });
    }

    if (enquiry.bulkUserUpdateId) {
        useEffect(() => {
            findValidBulkUsers(enquiry.bulkUserUpdateId, fileSubscriberType);
            findInvalidBulkUsers(enquiry.bulkUserUpdateId, fileSubscriberType);
            setLoading(false);
        }, []);
    } else {
        setErrors({file: 'Invalid bulkUserUpdateId'});
    }

    return (
        <Card>
            <CardHeader title={enquiryType.title + " - " + stepName} titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
            <Divider/>
            <CardContent>
                {errors.submit && isAlertVisible &&(
                    <Box mb={3}>
                        <Alert
                            onClose={() => setAlertVisible(false)}
                            severity="info"
                        >
                            {errors.submit}
                        </Alert>
                    </Box>
                )}
                {isSubmitting || isLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        my={5}
                    >
                        <CircularProgress/>
                    </Box>
                ) : (
                    <Box mx="auto"
                    >
                        {validBulkUsers != undefined && validBulkUsers.length > 0 && (
                            <Box mt={2}>
                                <Box mt={2}>
                                    <Typography
                                        variant="h6"
                                        color="textPrimary"
                                        align="center"
                                    >
                                        {fileSubscriberType == 'STUDENT' ? 'Students' : 'Teachers'}
                                    </Typography>
                                </Box>
                                <Box
                                    mt={2}
                                    justifyContent="center"
                                >
                                    <BulkUserTable users={validBulkUsers}/>
                                </Box>
                            </Box>
                        )}
                        {invalidBulkUsers != undefined && invalidBulkUsers.length > 0 && (
                            <Fragment>
                                <Box mt={2}>
                                    <Typography
                                        variant="h6"
                                        color="textPrimary"
                                        align="center"
                                    >
                                        Invalid Users
                                    </Typography>
                                </Box>
                                <Box
                                    mt={2}
                                    justifyContent="center"
                                >
                                    <BulkUserTable users={invalidBulkUsers}/>
                                </Box>
                            </Fragment>
                        )}
                        {nextStepFinal &&
                            <Box mt={2}>
                                <TextField
                                    fullWidth
                                    label="Additional notes"
                                    name="enquiryText"
                                    value={enquiry.enquiryText}
                                    onChange={handleUpdateEnquiryTest}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        }
                        <Box
                            mt={6}
                            display="flex"
                        >
                            {onBack && (
                                <Button
                                    onClick={() => {setLoading(true); onBack();}}
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Previous
                                </Button>
                            )}
                            <Box flexGrow={1}/>
                            <Button
                                onClick={handleCreateBulkUserUpdateTicket}
                                color="secondary"
                                variant="contained"
                                size="large"
                                disabled={isSubmitting}
                            >
                                {nextStepFinal ? 'Complete' : 'Next'}
                            </Button>
                        </Box>
                    </Box>)}
            </CardContent>
        </Card>
    );
}

ReviewUploadFile.propTypes = {
    enquiryType: PropTypes.object,
    stepName: PropTypes.string,
    enquiry: PropTypes.object,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    nextStepFinal: PropTypes.bool
};

export default ReviewUploadFile;
