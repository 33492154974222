import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';

class AuthService {
    setAxiosInterceptors = ({onLogout}) => {
        axios.interceptors.request.use(
            (requestConfig) => {
                return requestConfig;
            },
        );
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    this.setUserSession(null);

                    if (onLogout) {
                        onLogout();
                    }
                }

                return Promise.reject(error);
            }
        );
    };

    handleAuthentication() {
        const accessToken = this.getAccessToken();

        if (!accessToken) {
            return;
        }

        if (this.isValidToken(accessToken)) {
            this.setUserSession(accessToken);
        } else {
            this.setUserSession(null);
        }
    }

    loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
        axios.post('/web/ui/auth/public/login', {email, password})
            .then((response) => {
                if (response.data.userLoginResult.loggedIn) {
                    this.setUserSession(response.data.userSession);
                }
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });

    initServiceDeskSession = () => new Promise((resolve, reject) => {
        axios.get('/web/ui/serviceDesk/public/initServiceDeskSession')
            .then((response) => {
                if(response.data.loggedIn || response.data.publicSession) {
                    this.setUserSession(response.data);
                } else {
                    this.setUserSession(null);
                }

                resolve(response.data);
            })
            .catch((error) => {
                console.log('initServiceDeskSession Error');
                this.setUserSession(null);
                reject(error);
            });
    });

    loginInWithToken = () => new Promise((resolve, reject) => {
        axios.get('/web/ui/auth/public/loginWithToken')
            .then((response) => {
                if (response.data.userLoginResult.loggedIn) {
                    this.setUserSession(response.data.userSession);
                }
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });

    logout = () => new Promise((resolve, reject) => {
        this.setUserSession(null);
        axios.post('/web/ui/auth/public/logout')
            .then((response) => {
                console.log('logout response', response.data);
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });

    loadUserSession = () => new Promise((resolve, reject) => {
        axios.get('/web/ui/session/public/userSession')
            .then((response) => {
                if (response.data.loggedIn) {
                    this.setUserSession(response.data);
                }
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });

    setUserSession = (userSession) => {
        if (userSession) {
            localStorage.setItem('userSession', userSession);
        } else {
            localStorage.removeItem('userSession');
        }
    }

    getUserSession = () => localStorage.getItem('userSession');

    setAccessToken = (accessToken) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        } else {
            localStorage.removeItem('accessToken');
            delete axios.defaults.headers.common.Authorization;
        }
    }

    getAccessToken = () => localStorage.getItem('accessToken');

    isValidToken = (accessToken) => {
        if (!accessToken) {
            return false;
        }

        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        return decoded.exp > currentTime;
    }

    isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
