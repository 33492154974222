import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {
    Box,
    Button,
    FormHelperText,
    TextField,
    Typography,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Card,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Alert } from '@mui/material';
import axios from "../../../utils/axios";
import {useTheme} from "@mui/material/styles";

const validationShape = {
    importUserType: Yup.string().required('Required'),
    regionId: Yup.number().required('Required').min(1, 'Required'),
    schoolId: Yup.number().required('Required'),
    booksellerSchoolId: Yup.number().when('importUserType', {
        is: value => value == 'BOOKSELLER',
        then: Yup.number().required('Required').min(1, 'Required')
    })
};
const emptySchool = {schoolId: -1, schoolName: "I can't find my school"};
const undefinedSchool = {schoolId: undefined, schoolName: '-'};
const userTypeOptions = [{name: 'SCHOOL', description: 'School'},{name: 'HOME_SCHOOL', description: 'Home Schooler/Tutor'},{name: 'BOOKSELLER', description: 'Bookseller'}];

function SchoolInformation({
                               enquiryType,
                               stepName,
                               enquiry,
                               onBack,
                               onNext,
                               onChangeEnquiry
                           }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [salesUsers, setSalesUsers] = useState([]);
    const [bookSellerSchools, setBookSellerSchools] = useState([]);
    const [regions, setRegions] = useState([]);
    const [schools, setSchools] = useState([undefinedSchool]);
    const [school, setSchool] = useState(undefinedSchool);
    const [region, setRegion] = useState(undefined);

    const theme = useTheme();
    const styles = {
        formControl: {
            minWidth: 300,
        },
        required: {
            color: theme.palette.error.main
        }
    };

    const formik = useFormik({
        initialValues: enquiry,
        validationSchema: Yup.object().shape(validationShape),
        onSubmit: (values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
        }) => {
            axios.post('/web/ui/bulkUser/updateBulkUserEnquiry', values).then(response => {
                if(response.data) {
                    setStatus({success: true});
                    //setSubmitting(false);
                    if (onChangeEnquiry) {
                        onChangeEnquiry(values);
                    }
                    if (onNext) {
                        onNext();
                    }
                } else {
                    setErrors({submit: 'Error contact details'});
                    setStatus({success: false});
                    setSubmitting(false);
                }
            }).catch(error => {
                setErrors({submit: error.message});
                setStatus({success: false});
                setSubmitting(false);
            });
        }
    });

    const handleChangeRegion = (event) => {
        if(event.target.value) {
            const regionId = parseInt(event.target.value);
            formik.setFieldValue('regionId', regionId);
            formik.setFieldValue('salesUserId', undefined);
            if(regionId > 0) {
                const updatedRegion = regions.find(element => element.regionId == regionId);
                if(updatedRegion) {
                    setRegion(updatedRegion);
                    enquiry.regionId = updatedRegion.regionId;
                } else {
                    setRegion(undefined);
                    enquiry.regionId = -1;
                }
            } else {
                setRegion(undefined);
                enquiry.regionId = -1;
                enquiry.salesUserId = undefined;
            }
        } else {
            setRegion(undefined);
            enquiry.regionId = -1;
        }

        formik.validateForm();
        onChangeEnquiry(enquiry);
    }

    const handleChangeSchool = (event, newSchool, reason) => {
        if(newSchool) {
            formik.setFieldValue('schoolId', newSchool.schoolId);
            setSchool(newSchool);
        } else if(newSchool === null) {
            formik.setFieldValue('schoolId', -1);
            setSchool(undefinedSchool);
        }
    }

    const handleChangeUserType = (event) => {
        const importUserType = event.target.value;
        if(importUserType) {
            formik.setFieldValue('importUserType', importUserType);
            enquiry.importUserType = importUserType;
            onChangeEnquiry(enquiry);
        }
    }

    const loadSalesUsers = (regionId) => {
        if (regionId) {
            axios.get('/web/ui/serviceDesk/salesUsers', {params: {regionId: regionId}})
                .then(response => {
                    if(response.data.length == 1) {
                        formik.values.salesUserId = response.data[0].userId;
                    }
                    setSalesUsers(response.data);
                }).catch(error => {
                setSalesUsers([]);
            });
        }
    };

    const loadBooksellerSchools = () => {
        axios.get('/web/ui/serviceDesk/booksellerSchools')
            .then(response => {
                setBookSellerSchools(response.data);
            }).catch(error => {
            setBookSellerSchools([]);
        });
    };

    const loadSchools = (regionId) => {
        axios.get('/web/ui/serviceDesk/schools', {params: {regionId: regionId}})
            .then(response => {
                response.data.unshift(emptySchool);
                response.data.unshift(undefinedSchool);
                setSchools(response.data);
                if(response.data.length > 0 && enquiry.schoolId) {
                    setSchool(response.data.find(element => element.schoolId == enquiry.schoolId));
                } else {
                    setSchool(undefinedSchool);
                }
            }).catch(error => {
                setSchools([undefinedSchool]);
                setSchool(undefinedSchool);
            });
    }

    const loadRegions = () => {
        axios.get('/web/ui/serviceDesk/regions')
            .then(response => {
                const updatedRegions = response.data;
                if(updatedRegions != undefined) {
                    setRegions(updatedRegions);

                    if (updatedRegions.length > 0) {
                        if(enquiry.regionId) {
                            setRegion(updatedRegions.find(element => element.regionId == enquiry.regionId));
                        } else {
                            setRegion(undefined);
                        }
                    }
                } else {
                    setRegions([]);
                }
            }).catch(error => {
                setRegions([]);
            });
    };

    useEffect(() => {
        loadRegions();
        loadBooksellerSchools();
        setLoading(false);
    }, []);

    useEffect(() => {
        if (region) {
            loadSalesUsers(region.regionId);
            loadSchools(region.regionId);
        }
    }, [region]);

    useEffect(() => {
        if(formik.errors && Object.keys(formik.errors).length > 0) {
            setAlertVisible(true);
        }
    }, [formik.errors]);

    return (
        <Card>
            <CardHeader title={enquiryType.title + " - " + stepName} titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
            <Divider/>
            <CardContent>
                {isAlertVisible && formik.errors.schoolId != undefined && (
                    <Box mb={3}>
                        <Alert
                            onClose={() => {setAlertVisible(false);}}
                            severity="info"
                        >
                            {formik.errors.schoolId}
                        </Alert>
                    </Box>
                )}
                {formik.isSubmitting || isLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        my={5}
                    >
                        <CircularProgress/>
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Box>
                            <Box mt={2}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Please provide us your school and bookseller details <span style={{...styles.required}}>*</span>
                                </Typography>
                            </Box>
                            <Box
                                mt={2}
                            >
                                <FormControl variant="outlined" style={{...styles.formControl}} error={Boolean(formik.errors.importUserType)}>
                                    <InputLabel id="user-type-label">Are you a school, bookseller or homeschool/tutor?</InputLabel>
                                    <Select fullWidth
                                            labelId="user-type-label"
                                            id="importUserType"
                                            name="importUserType"
                                            value={formik.values.importUserType}
                                            onChange={handleChangeUserType}
                                            label="Are you a school, bookseller or homeschool/tutor?"
                                            style={{width: 400}}
                                    >
                                        {userTypeOptions.map((userTypeOption) => (
                                            <MenuItem key={userTypeOption.name} value={userTypeOption.name}>
                                                {userTypeOption.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {Boolean(formik.errors.importUserType) && (
                                        <FormHelperText error>
                                            {formik.errors.importUserType}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Box>
                            {regions.length > 0 && (
                                <Box
                                    mt={2}
                                >
                                    <Typography color="textSecondary" gutterBottom={true}>
                                        Select your state <span style={{...styles.required}}>*</span>
                                    </Typography>
                                    <FormControl variant="outlined" style={{...styles.formControl}} error={Boolean(formik.errors.regionId)}>
                                        <InputLabel id="region-label">State</InputLabel>
                                        <Select fullWidth
                                                labelId="region-label"
                                                id="regionId"
                                                name="regionId"
                                                value={formik.values.regionId ? formik.values.regionId : -1}
                                                onChange={handleChangeRegion}
                                                onBlur={formik.handleBlur}
                                                label="State"
                                        >
                                            <MenuItem value={-1}>-</MenuItem>
                                            {regions.map((regionOption) => (
                                                <MenuItem value={regionOption.regionId}
                                                          key={regionOption.regionId}>{regionOption.regionDesc}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(formik.errors.regionId) && (
                                            <FormHelperText error>
                                                {formik.errors.regionId}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                            )}
                            <Box mt={2}>
                                <Typography color="textSecondary" gutterBottom={true}>
                                    Select your school <span style={{...styles.required}}>*</span>
                                </Typography>
                                <Autocomplete
                                    id="schoolId"
                                    name="schoolId"
                                    options={schools}
                                    getOptionLabel={(option) => option.schoolName}
                                    isOptionEqualToValue={(option, value) => value && option.schoolId == value.schoolId}
                                    style={{ width: 400 }}
                                    label="School"
                                    onBlur={handleChangeSchool}
                                    onChange={handleChangeSchool}
                                    value={school}
                                    renderInput={(params) => <TextField {...params} label="School" variant="outlined" />}
                                    disabled={region == undefined}
                                />
                                {Boolean(formik.errors.schoolId) && (
                                    <FormHelperText error>
                                        {formik.errors.schoolId}
                                    </FormHelperText>
                                )}
                            </Box>
                            <Box mt={2} display="none">
                                <TextField
                                    error={Boolean(formik.touched.schoolEmail && formik.errors.schoolEmail)}
                                    helperText={formik.touched.schoolEmail && formik.errors.schoolEmail}
                                    label="School Email"
                                    name="schoolEmail"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="email"
                                    value={formik.values.schoolEmail}
                                    variant="outlined"
                                />
                            </Box>
                            {(enquiry.importUserType == 'BOOKSELLER' || enquiry.importUserType == 'SCHOOL') && bookSellerSchools.length > 0 && (
                                <Fragment>
                                    <Box mt={2}>
                                        <Typography color="textSecondary" gutterBottom={true}>
                                            {(enquiry.importUserType == 'BOOKSELLER' ? (
                                                <span>Select bookseller</span>
                                            ) : (
                                                <span>Who is your bookseller?</span>
                                            ))}
                                        </Typography>
                                        <FormControl variant="outlined" style={{...styles.formControl}} error={Boolean(formik.errors.booksellerSchoolId)}>
                                            <InputLabel id="bookseller-label">Bookseller</InputLabel>
                                            <Select fullWidth
                                                    labelId="bookseller-label"
                                                    id="bookseller"
                                                    name="booksellerSchoolId"
                                                    value={formik.values.booksellerSchoolId != undefined ? formik.values.booksellerSchoolId: -1}
                                                    onChange={formik.handleChange}
                                                    displayEmpty={true}
                                                    autoWidth={true}
                                                    label="Bookseller"
                                            >
                                                <MenuItem value={-1} selected>
                                                    {(enquiry.importUserType == 'BOOKSELLER' ? (
                                                        <span>-</span>
                                                    ) : (
                                                        <span>I don't use a bookseller</span>
                                                    ))}
                                                </MenuItem>
                                                {bookSellerSchools.map((bookSellerSchool) => (
                                                    <MenuItem value={bookSellerSchool.schoolId}
                                                              key={bookSellerSchool.schoolId}>{bookSellerSchool.schoolName}</MenuItem>
                                                ))}
                                            </Select>
                                            {Boolean(formik.errors.booksellerSchoolId) && (
                                                <FormHelperText error>
                                                    {formik.errors.booksellerSchoolId}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                    {formik.values.booksellerSchoolId != undefined && formik.values.booksellerSchoolId > 0 && (
                                        <Box mt={2}>
                                            <TextField
                                                error={Boolean(formik.touched.booksellerEmail && formik.errors.booksellerEmail)}
                                                helperText={formik.touched.booksellerEmail && formik.errors.booksellerEmail}
                                                label="Bookseller Representative Email"
                                                name="booksellerEmail"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                type="email"
                                                value={formik.values.booksellerEmail}
                                                variant="outlined"
                                                style={{width: '280px'}}
                                            />
                                        </Box>
                                    )}
                                </Fragment>
                            )}
                            {enquiry.importUserType == 'SCHOOL' && (
                                <Fragment>
                                    <Box
                                        mt={2}
                                    >
                                        <Typography color="textSecondary" gutterBottom={true}>
                                            Who is your Cambridge sales representative?
                                        </Typography>
                                        <FormControl variant="outlined" style={{...styles.formControl}} disabled={region == undefined}>
                                            <InputLabel id="sales-user-label" style={{whitespace: 'nowrap'}}>Sales Representative</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="sales-user-label"
                                                id="sales-user"
                                                name="salesUserId"
                                                value={formik.values.salesUserId != undefined ? formik.values.salesUserId : -1}
                                                onChange={formik.handleChange}
                                                displayEmpty={true}
                                                autoWidth={true}
                                                label="Sales Representative"
                                                style={{minWidth: '250px'}}
                                            >
                                                {region !== undefined && salesUsers.length !== 1 &&
                                                    <MenuItem value={-1}>I don't know</MenuItem>
                                                }
                                                {salesUsers.map((salesUser) => (
                                                    <MenuItem value={salesUser.userId}
                                                              key={salesUser.userId}>{salesUser.fullName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Fragment>
                            )}
                        </Box>
                        <Box
                            mt={6}
                            display="flex"
                        >
                            {onBack && (
                                <Button
                                    onClick={() => {setLoading(true); onBack();}}
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Previous
                                </Button>
                            )}
                            <Box flexGrow={1}/>
                            <Button
                                color="secondary"
                                disabled={formik.isSubmitting || !formik.isValid}
                                type="submit"
                                variant="contained"
                                size="large"
                            >
                                Next
                            </Button>
                        </Box>
                    </form>
                )}
            </CardContent>
        </Card>
    );
}

SchoolInformation.propTypes = {
    enquiryType: PropTypes.object,
    stepName: PropTypes.string,
    enquiry: PropTypes.object,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onChangeEnquiry: PropTypes.func
};

export default SchoolInformation;
