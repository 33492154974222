import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Box,
    Button,
    FormHelperText,
    SvgIcon,
    TextField,
    Typography,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Grid,
    Card,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { Upload as UploadIcon } from 'react-feather';
import { Alert } from '@mui/material';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from "../../../utils/axios";
import Autocomplete from '@mui/material/Autocomplete';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import bytesToSize from "../../../utils/bytesToSize";
import { useTheme } from "@mui/material/styles";

const emptySchool = { schoolId: -1, schoolName: "I can't find my school" };
const undefinedSchool = { schoolId: undefined, schoolName: '-' };

const activationCodeMessages = new Map();
activationCodeMessages.set(73270031, 'This code has been activated already. It can be activated again with a <b>reactivation code</b>. Reactivation codes can be purchased from our <a href="https://www.cambridge.edu.au/education" target="_blank">store</a>');
activationCodeMessages.set(190000005, 'We can’t find this code in our system. If you have purchased this code and believe this is an error, please continue with lodging an enquiry.');
activationCodeMessages.set(73270013, 'This code has reached its maximum number of activations and cannot be used again. If you have purchased this code and believe this is an error, please continue with lodging an enquiry.');
activationCodeMessages.set(25000000, 'This code can be activated. Sign into <a href="https://www.cambridge.org/go" target="_blank">Cambridge GO</a> to activate.');
activationCodeMessages.set(73270035, 'This is a reactivation code. It can only be used with a code from a secondhand textbook.');
activationCodeMessages.set(73270015, 'We can’t find this code in our system. If you have purchased this code and believe this is an error, please continue with lodging an enquiry.');

function EnquiryDetails({ enquiryType, enquiry, onBack, onNext, onChangeEnquiry , ...rest }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [isFileAlertVisible, setFileAlertVisible] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [regions, setRegions] = useState([]);
    const [schools, setSchools] = useState([undefinedSchool]);
    const [school, setSchool] = useState(undefinedSchool);
    const [region, setRegion] = useState(undefined);
    const [files, setFiles] = useState([]);
    const [isFileSelected, setFileSelected] = useState(false);
    const [activationCodeMessage, setActivationCodeMessage] = useState();
    const [activationCodeValid, setActivationCodeValid] = useState();
    const [validatingActivationCode, setValidatingActivationCode] = useState();

    const { executeRecaptcha } = useGoogleReCaptcha();
    const fileInputRef = React.useRef();

    const theme = useTheme();
    const styles = {
        required: {
            color: theme.palette.error.main
        },
        fontIcon: {
            fontSize: '34px',
            cursor: 'pointer'
        },
        success: {
            color: '#006644',
            fontWeight: 'bold'
        }
    };

    let validationShape = {
        firstName: Yup.string().min(2, 'Must be at least 2 characters').max(200).required('Required'),
        lastName: Yup.string().min(2, 'Must be at least 2 characters').max(200).required('Required'),
        email: Yup.string().email().max(200).required('Required'),
        enquiryEmail: Yup.string().email().max(200),
        phone: Yup.string().min(8, 'Must be at least 8 characters').max(20),
        enquiryText: Yup.string().min(3, 'Must be at least 3 characters').max(5000).required('Required')
    };

    if (enquiry.enquiryType != 'ERRATA') {
        validationShape = {
            ...{
                regionId: Yup.number().required('Required').min(1, 'Required'),
                schoolId: Yup.number().required('Required'),
            },
            ...validationShape
        };
    }

    if (enquiry.enquiryType == 'ACTIVATION_CODE') {
        validationShape = {
            ...{
                activationCode: Yup.string().trim().matches(/^[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}$/, 'Activation code not in correct format. Correct format is 1111-2222-3333-4444').required('Required'),
                reactivationCode: Yup.string().trim().matches(/^[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}$/, 'Activation code not in correct format. Correct format is 1111-2222-3333-4444'),
            },
            ...validationShape
        };
    } else if (enquiry.enquiryType == 'ERRATA') {
        validationShape = {
            ...{
                textbookMedia: Yup.string().required('Required'),
                contentLocation: Yup.string().min(3, 'Must be at least 3 characters').max(500).required('Required'),
            },
            ...validationShape
        };
    }

    const formik = useFormik({
        initialValues: enquiry,
        validationSchema: Yup.object().shape(validationShape),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
            const token = executeRecaptcha("enquiry_details");
            token.then(result => {
                values.recaptchaToken = result;

                let url = '/web/ui/serviceDesk/createEnquiry';
                let contentType = 'application/json'
                let postData = values;
                if(files && files.length > 0) {
                    url = '/web/ui/serviceDesk/createEnquiryWithAttachment';
                    contentType = 'multipart/form-data'

                    postData = new FormData();
                    postData.append('enquiry', new Blob([JSON.stringify(values)], { type: 'application/json' }));

                    files.forEach((file, index) => {
                        postData.append(`file`, file);
                    });
                }

                axios.post(url, postData, { headers: {'Content-Type': contentType} }).then(response => {
                    if (response.data) {
                        setStatus({ success: true });
                        onChangeEnquiry(response.data);
                        if (onNext) {
                            onNext();
                        }
                    } else {
                        setErrors({ submit: 'Error submitting enquiry' });
                        setAlertVisible(true);
                        setStatus({ success: false });
                        setSubmitting(false);
                    }
                }).catch(error => {
                    setErrors({ submit: error.response?.data?.message || error.message });
                    setAlertVisible(true);
                    setStatus({ success: false });
                    setSubmitting(false);
                });
            }).catch(error => {
                setErrors({ submit: error.message });
                setAlertVisible(true);
                setStatus({ success: false });
                setSubmitting(false);
            });
        }
    });

    const loadSchools = (regionId) => {
        axios.get('/web/ui/serviceDesk/schools', { params: { regionId: regionId } })
            .then(response => {
                response.data.unshift(emptySchool);
                response.data.unshift(undefinedSchool);
                setSchools(response.data);
                if (response.data.length > 0 && enquiry.schoolId) {
                    setSchool(response.data.find(element => element.schoolId == enquiry.schoolId));
                } else {
                    setSchool(undefinedSchool);
                }
            }).catch(error => {
            setSchools([undefinedSchool]);
            setSchool(undefinedSchool);
        });
    }

    const loadRegions = () => {
        axios.get('/web/ui/serviceDesk/regions')
            .then(response => {
                const updatedRegions = response.data;
                setRegions(updatedRegions);

                if (updatedRegions.length > 0) {
                    if (enquiry.regionId) {
                        setRegion(updatedRegions.find(element => element.regionId == enquiry.regionId));
                    } else {
                        setRegion(undefined);
                    }
                }
            }).catch(error => {
            setRegions([]);
        });
    };

    const handleChangeSchool = (event, newSchool, reason) => {
        if (newSchool) {
            formik.setFieldValue('schoolId', newSchool.schoolId);
            setSchool(newSchool);
        } else if (newSchool === null) {
            formik.setFieldValue('schoolId', -1);
            setSchool(undefinedSchool);
        }
    };

    const handleChangeRegion = (event) => {
        if(event.target.value) {
        const regionId = parseInt(event.target.value);
        formik.setFieldValue('regionId', regionId);
        formik.setFieldValue('salesUserId', undefined);

        if (regionId > 0) {
                const updatedRegion = regions.find(element => element.regionId == regionId);
            if (updatedRegion) {
                setRegion(updatedRegion);
                enquiry.regionId = updatedRegion.regionId;
            } else {
                setRegion(undefined);
                enquiry.regionId = -1;
            }
        } else {
            setRegion(undefined);
            enquiry.regionId = -1;
            enquiry.salesUserId = undefined;
        }
        } else {
            setRegion(undefined);
            enquiry.regionId = -1;
        }

        formik.validateForm();
    }

    const handleFileSelect = (event) => {
        const selectedFiles = Array.from(event.target.files);

        if (files.length + selectedFiles.length > 5) {
            setFileAlertVisible(true);
            return;
        }
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
    };

    const handleFileRemove = (fileToRemove) => {
        setFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    };

    const handleBlurActivationCode = (e) => {
        formik.handleBlur(e);
        const activationCode = e.currentTarget.value.toUpperCase().trim();
        formik.setFieldValue('activationCode', activationCode);
        validateActivationCode(activationCode);
    }

    const handleChangeActivationCode = (e) => {
        formik.handleChange(e);
        const activationCode = e.currentTarget.value.toUpperCase().trim();
        formik.setFieldValue('activationCode', activationCode);
        validateActivationCode(activationCode);
    }

    const validateActivationCode = (activationCode) => {
        if (activationCode && activationCode.match(/^[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}$/)) {
            setValidatingActivationCode(true);
            axios.get('/web/ui/serviceDesk/validateAccessCode', { params: { accessCode: activationCode } })
                .then(response => {
                    if (response.data && response.data.success == false) {
                        setActivationCodeMessage(activationCodeMessages.get(response.data.data.code) || response.data.message || 'We can’t find this code in our system.');
                        setActivationCodeValid(false);
                    } else if (response.data && response.data.success == true) {
                        setActivationCodeMessage(activationCodeMessages.get(response.data.data.code) || response.data.message);
                        setActivationCodeValid(true);
                    } else {
                        setActivationCodeMessage(response.data.message || 'We can’t find this code in our system.');
                        setActivationCodeValid(false);
                    }
                    setValidatingActivationCode(false);
                }).catch(error => {
                setActivationCodeMessage('Error validating activation code');
                setValidatingActivationCode(false);
            });
        } else {
            setActivationCodeMessage(undefined);
            setValidatingActivationCode(false);
        }
    };

    useEffect(() => {
        loadRegions();
        setLoading(false);
    }, []);

    useEffect(() => {
        if (region) {
            loadSchools(region.regionId);
        }
    }, [region]);

    useEffect(() => {
        setFileSelected(files && files.length > 0);
        setFileAlertVisible(files && files.length > 5);
    }, [files]);


    return (
        <Card>
            <CardHeader title={enquiryType.title} titleTypographyProps={{ variant: 'h5', color: 'secondary' }} />
            <Divider />
            <CardContent>
                {formik.errors.submit && isAlertVisible && (
                    <Box mb={3}>
                        <Alert
                            onClose={() => setAlertVisible(false)}
                            severity="info"
                        >
                            {formik.errors.submit}
                        </Alert>
                    </Box>
                )}
                {formik.isSubmitting ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        my={5}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                    fullWidth
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    label="First Name"
                                    name="firstName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                    fullWidth
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    label="Last Name"
                                    name="lastName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Box mt={2}>
                            <TextField
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                fullWidth
                                helperText={formik.touched.email && formik.errors.email}
                                label="Email Address"
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="email"
                                value={formik.values.email}
                                variant="outlined"
                            />
                        </Box>
                        {enquiry.enquiryType != 'ERRATA' && regions.length > 0 && (
                            <Box mt={2}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <Typography color="textSecondary" gutterBottom>
                                            Select your state <span style={{ ...styles.required }}>*</span>
                                        </Typography>
                                        <FormControl variant="outlined" error={Boolean(formik.errors.regionId)} fullWidth>
                                            <InputLabel id="region-label">State</InputLabel>
                                            <Select fullWidth
                                                    labelId="region-label"
                                                    id="regionId"
                                                    name="regionId"
                                                    value={formik.values.regionId || -1}
                                                    onChange={handleChangeRegion}
                                                    onBlur={formik.handleBlur}
                                                    label="State"
                                            >
                                                <MenuItem value={-1}>-</MenuItem>
                                                {regions.map((regionOption) => (
                                                    <MenuItem value={regionOption.regionId} key={regionOption.regionId}>{regionOption.regionDesc}</MenuItem>
                                                ))}
                                            </Select>
                                            {Boolean(formik.errors.regionId) && (
                                                <FormHelperText error>
                                                    {formik.errors.regionId}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <Typography color="textSecondary" gutterBottom>
                                            Select your school <span style={{ ...styles.required }}>*</span>
                                        </Typography>
                                        <Autocomplete
                                            id="schoolId"
                                            name="schoolId"
                                            options={schools}
                                            getOptionLabel={(option) => option.schoolName}
                                            isOptionEqualToValue={(option, value) => value && option.schoolId == value.schoolId}
                                            style={{ width: 300 }}
                                            label="School"
                                            onBlur={handleChangeSchool}
                                            onChange={handleChangeSchool}
                                            value={school}
                                            renderInput={(params) => <TextField {...params} label="School" variant="outlined" />}
                                            disabled={region == undefined}
                                        />
                                        {Boolean(formik.errors.schoolId) && (
                                            <FormHelperText error>
                                                {formik.errors.schoolId}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        {enquiry.enquiryType == 'ACTIVATION_CODE' && (
                            <Box mt={2}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formik.touched.activationCode && formik.errors.activationCode)}
                                            fullWidth
                                            helperText={formik.touched.activationCode && formik.errors.activationCode}
                                            label="Activation Code"
                                            name="activationCode"
                                            onBlur={formik.handleBlur}
                                            onChange={handleChangeActivationCode}
                                            value={formik.values.activationCode}
                                            variant="outlined"
                                        />
                                        {!validatingActivationCode && activationCodeMessage != undefined && (
                                            <FormHelperText error={!activationCodeValid} style={{ ...(activationCodeValid && styles.success) }}>
                                                <span dangerouslySetInnerHTML={{ __html: activationCodeMessage }}></span>
                                            </FormHelperText>
                                        )}
                                        {validatingActivationCode &&
                                            <FormHelperText error>
                                                <span style={{ margin: '0 5px 0 0' }}>Validating</span>
                                                <CircularProgress size={18} />
                                            </FormHelperText>
                                        }
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formik.touched.reactivationCode && formik.errors.reactivationCode)}
                                            fullWidth
                                            helperText={formik.touched.reactivationCode && formik.errors.reactivationCode}
                                            label="Reactivation Code"
                                            name="reactivationCode"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.reactivationCode}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        {enquiry.enquiryType == 'ERRATA' && (
                            <>
                                <Box mt={2}>
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                    >
                                        Please let us know where you found this error?
                                    </Typography>
                                    <FormControl variant="outlined">
                                        <RadioGroup row aria-label="textbookMedia" name="textbookMedia" value={formik.values.textbookMedia} onChange={formik.handleChange}>
                                            <FormControlLabel value="print_or_offline_textbook" control={<Radio />} label="Print or Offline textbook" />
                                            <FormControlLabel value="interactive_textbook" control={<Radio />} label="Interactive textbook" />
                                        </RadioGroup>
                                    </FormControl>
                                    {Boolean(formik.errors.textbookMedia) && (
                                        <FormHelperText error>
                                            {formik.errors.textbookMedia}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <Box mt={2}>
                                    <TextField
                                        error={Boolean(formik.touched.contentLocation && formik.errors.contentLocation)}
                                        fullWidth
                                        helperText={formik.touched.contentLocation && formik.errors.contentLocation}
                                        label="Textbook title / Year / Chapter / lesson"
                                        name="contentLocation"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.contentLocation}
                                        variant="outlined"
                                    />
                                </Box>
                            </>
                        )}
                        {(enquiry.enquiryType == 'PASSWORD' || enquiry.enquiryType == 'EMAIL_VERIFICATION') && (
                            <Box mt={2}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Who would you like to enquire about?
                                </Typography>
                                <FormControl variant="outlined">
                                    <RadioGroup row aria-label="subscriberType" name="subscriberType" value={formik.values.subscriberType} onChange={formik.handleChange}>
                                        <FormControlLabel value="STUDENT" control={<Radio />} label="Students" />
                                        <FormControlLabel value="TEACHER" control={<Radio />} label="Teachers" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        )}
                        {(enquiry.enquiryType == 'PASSWORD') && (
                            <Box mt={2}>
                                <TextField
                                    error={Boolean(formik.touched.enquiryEmail && formik.errors.enquiryEmail)}
                                    fullWidth
                                    helperText={formik.touched.enquiryEmail && formik.errors.enquiryEmail}
                                    label="Email address you are enquiring about"
                                    name="enquiryEmail"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.enquiryEmail}
                                    variant="outlined"
                                />
                            </Box>
                        )}
                        <Box mt={2}>
                            <TextField
                                error={Boolean(formik.touched.enquiryText && formik.errors.enquiryText)}
                                fullWidth
                                helperText={formik.touched.enquiryText && formik.errors.enquiryText}
                                label={enquiry.enquiryType == 'ERRATA' ? 'Comments' : 'Enquiry'}
                                name="enquiryText"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.enquiryText}
                                variant="outlined"
                                multiline
                                rows={4}
                            />
                        </Box>
                        <Box mt={2}>
                            {isFileAlertVisible &&
                                <Box mb={3}>
                                    <Alert severity="error">You can only upload a maximum 5 files</Alert>
                                </Box>
                            }

                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="span"
                                style={{ margin: '2px 5px' }}
                            >
                                {(enquiry.enquiryType == 'PASSWORD') && (
                                    <span>Attach screen shots of the log in error (max 5 files)</span>
                                )}
                                {(enquiry.enquiryType == 'ERRATA') && (
                                    <span>Attach screen shots of the textbook error (max 5 files)</span>
                                )}
                                {(enquiry.enquiryType != 'PASSWORD' && enquiry.enquiryType != 'ERRATA') && (
                                    <span>Select files to attach (max 5 files)</span>
                                )}
                            </Typography>
                            <input
                                style={{ display: 'none' }}
                                id="file"
                                name="file"
                                type="file"
                                onChange={handleFileSelect}
                                ref={fileInputRef}
                                multiple
                            />
                            <label htmlFor="file">
                                <Button size="medium" variant="contained" component="span" color="secondary">
                                    <SvgIcon
                                        fontSize="small"
                                    >
                                        <UploadIcon />
                                    </SvgIcon>
                                    &nbsp;Files
                                </Button>
                            </label>
                            {files.map((file, index) => (
                                <Box key={index} display="flex" alignItems="center" mt={1}>
                                    <InsertDriveFileIcon style={{ verticalAlign: 'middle' }} />
                                    <Typography
                                        color="textPrimary"
                                        variant="body2"
                                        component="span"
                                        style={{verticalAlign: 'middle'}}
                                    >
                                        {file.name} &nbsp;({bytesToSize(file.size)})
                                    </Typography>
                                    <DeleteForeverOutlinedIcon style={{ ...styles.fontIcon, verticalAlign: 'middle' }} onClick={() => handleFileRemove(file)} />
                                </Box>
                            ))}
                        </Box>
                        <Box
                            mt={6}
                            display="flex"
                        >
                            {onBack && (
                                <Button
                                    onClick={onBack}
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Previous
                                </Button>
                            )}
                            <Box flexGrow={1} />
                            <Button
                                color="secondary"
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="contained"
                                size="large"
                            >
                                Next
                            </Button>
                        </Box>
                    </form>
                )}
            </CardContent>
        </Card>
    );
}

EnquiryDetails.propTypes = {
    enquiryType: PropTypes.object,
    enquiry: PropTypes.object,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onChangeEnquiry: PropTypes.func
};

export default EnquiryDetails;
