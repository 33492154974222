import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import Page from '../../components/Page';
import DashboardLayout from "../../layouts/DashboardLayout";
import { useTheme } from '@mui/material/styles';

function Error401View() {
  const theme = useTheme();
  const styles = {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '80%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
      paddingTop: 80,
      paddingBottom: 80
    },
    image: {
      maxWidth: '100%',
      width: 560,
      maxHeight: 300,
      height: 'auto'
    }
  };
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
      <DashboardLayout>
        <Page
            style={{...styles.root}}
          title="Session Expired"
        >
          <Container maxWidth="lg">
            <Typography
              align="center"
              variant={'h5'}
              color="textPrimary"
            >
              Your session has expired
            </Typography>
            <Box
              mt={4}
              display="flex"
              justifyContent="center"
            >
              <Button
                color="secondary"
                component={RouterLink}
                to="/"
                variant="contained"
                size="large"
              >
                Start Enquiry
              </Button>
            </Box>
          </Container>
        </Page>
      </DashboardLayout>
  );
}

export default Error401View;
