import React, {
    createContext,
    useState,
    useEffect
} from 'react';
import PropTypes from 'prop-types';


const UserContext  = React.createContext();

const defaultUser = { };

export function UserProvider({ user, children }) {
    const [currentUser, setCurrentUser] = useState(user || defaultUser);

    const handleSaveUser = (updatedUser = {}) => {
        setCurrentUser(updatedUser);
    };

    return (
        <UserContext.Provider
            value={{
                user: currentUser,
                saveUser: handleSaveUser
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
    settings: PropTypes.object
};

export const UserConsumer = UserContext.Consumer;

export default UserContext;