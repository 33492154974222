import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Logo from './Logo';
import { useTheme } from '@mui/material/styles';

function SlashScreen() {
  const theme = useTheme();
  const styles = {
    root: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      padding: theme.spacing(3),
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    },
    logo: {
      width: 200,
      maxWidth: '100%'
    }
  };

  return (
    <div style={{...styles.root}}>
      <Box
        display="flex"
        justifyContent="center"
        mb={6}
      >
        <Logo style={{...styles.logo}} />
      </Box>
      <CircularProgress />
    </div>
  );
}

export default SlashScreen;
