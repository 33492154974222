import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Grid,
    Checkbox,
    Card,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert } from '@mui/material';
import axios from "../../../utils/axios";
import Autocomplete from '@mui/material/Autocomplete';
import {matchSorter} from 'match-sorter';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AssignProducts({enquiryType,
                            fileSubscriberType,
                            stepName,
                            enquiry,
                            onBack,
                            onNext,
                            onChangeEnquiry
                          }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState({});
    const [bulkUsers, setBulkUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [resourceAssignmentType, setResourceAssignmentType] = useState('ALL');
    const [classNames, setClassNames] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [years, setYears] = useState([]);
    const [productBeans, setProductBeans] = useState([]);
    const [productSubjects, setProductSubjects] = useState([]);
    const [productSubject, setProductSubject] = useState();
    const [regions, setRegions] = useState([]);
    const [regionId, setRegionId] = useState();
    const noValue = '-';

    const filterOptions = (options, { inputValue }) => {
        const terms = inputValue.split(' ');
        if (!terms) {
            return options
        }

        return terms.reduceRight(
            (results, term) => matchSorter(results, term, {keys: ['defaultSyllabusGroupName','name', 'isbn','regionName'], threshold: matchSorter.rankings.WORD_STARTS_WITH, sorter: rankedItems => [...rankedItems].sort((a, b) => b.productSeq - a.productSeq)}),
            options,
        )
    }

    const findBulkUsers = (bulkUserUpdateId, subscriberType) => {
        axios.get('/web/ui/bulkUser/findBulkUsers', {params: {bulkUserUpdateId: bulkUserUpdateId, subscriberType: subscriberType, valid: true}})
            .then(response => {
                setBulkUsers(response.data);
            }).catch(error => {
            setBulkUsers([]);
        });
    }

    const findProducts = (bulkUserUpdateId, subscriberType, locRegionId, locProductSubject) => {
        axios.get('/web/ui/bulkUser/findProducts', {params: {bulkUserUpdateId: bulkUserUpdateId, subscriberType: subscriberType, regionId: locRegionId, subject: locProductSubject}})
            .then(response => {
                setProducts(response.data);
            }).catch(error => {
            setProducts([]);
        });
    }

    const findProductSubjects = (bulkUserUpdateId, subscriberType) => {
        axios.get('/web/ui/bulkUser/productSubjects', {})
            .then(response => {
                setProductSubjects(response.data);
            }).catch(error => {
            setProductSubjects([]);
        });
    }

    const findRegions = () => {
        axios.get('/web/ui/serviceDesk/regions')
            .then(response => {
                setRegions(response.data);
            }).catch(error => {
                setRegions([]);
            });
    };

    const findClassNames = (bulkUserUpdateId) => {
        axios.get('/web/ui/bulkUser/findClassNames', {params: {bulkUserUpdateId: bulkUserUpdateId}})
            .then(response => {
                setClassNames(response.data);
            }).catch(error => {
            setClassNames([]);
        });
    }

    const findSubjects = (bulkUserUpdateId) => {
        axios.get('/web/ui/bulkUser/findSubjects', {params: {bulkUserUpdateId: bulkUserUpdateId}})
            .then(response => {
                setSubjects(response.data);
            }).catch(error => {
            setSubjects([]);
        });
    }

    const findYears = (bulkUserUpdateId) => {
        axios.get('/web/ui/bulkUser/findYearLevels', {params: {bulkUserUpdateId: bulkUserUpdateId}})
            .then(response => {
                setYears(response.data);
            }).catch(error => {
                setYears([]);
            });
    }

    const loadProducts = (bulkUserUpdateId, subscriberType) => {
        setErrors({});
        axios.get('/web/ui/bulkUser/findBulkUserConfig', {params: {bulkUserUpdateId: bulkUserUpdateId, subscriberType: fileSubscriberType}})
            .then(response => {
                updateLocalUserProducts(response.data.bulkUserProducts, response.data.multiAuthority);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    };

    const handleChangeProduct = (event, selectedProducts, reason) => {
        if(selectedProducts) {
            setSelectedProducts(selectedProducts);
        } else {
            setSelectedProducts([])
        }
    }

    const handleChangeRegion = (event) => {
        if(event.target.value && event.target.value != -1) {
            setRegionId(event.target.value);
        } else {
            setRegionId(undefined)
        }
    }

    const handleChangeProductSubject = (event) => {
        if(event.target.value && event.target.value != -1) {
            setProductSubject(event.target.value);
        } else {
            setProductSubject(undefined)
        }
    }

    const handleChangeResourceAssignmentType = (event, resourceAssignmentType, reason) => {
        if(resourceAssignmentType) {
            setResourceAssignmentType(resourceAssignmentType)
        } else {
            setResourceAssignmentType('CLASS')
        }
    }

    const addProduct = () => {
        const subjectSelect = document.getElementsByName('subject')[0];
        const classSelect = document.getElementsByName('class')[0];
        const yearSelect = document.getElementsByName('year')[0];

        let productBean = {};
        if (subjectSelect) {
            const subjectSelectValue = subjectSelect.options[subjectSelect.selectedIndex].value;
            if (subjectSelectValue !== noValue) productBean.subject = subjectSelectValue;
        }
        if (classSelect) {
            const classSelectValue = classSelect.options[classSelect.selectedIndex].value;
            if (classSelectValue !== noValue) productBean.className = classSelectValue;
        }
        if (yearSelect) {
            const yearSelectValue = yearSelect.options[yearSelect.selectedIndex].value;
            if (yearSelectValue !== noValue) productBean.schoolYear = yearSelectValue;
        }
        if (selectedProducts && selectedProducts.length > 0) {
            let newProducts = productBeans;

            for(let selectedProduct of selectedProducts) {
                if (selectedProduct) {
                    const cloneProductBean = { ...productBean};
                    cloneProductBean.productId = selectedProduct.productId;
                    newProducts = [...newProducts, cloneProductBean];
                    // setSubmitting(true);
                }
            }

            setErrors({});
            axios.post('/web/ui/bulkUser/updateProductConfig', newProducts, {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId, subscriberType: fileSubscriberType}})
                .then(response => {
                    const userProducts = response.data.bulkUserProducts;
                    if (userProducts.length === newProducts.length) {
                        updateLocalUserProducts(userProducts, response.data.multiAuthority);
                        setSelectedProducts([]);
                    } else {
                        setErrors({products: "Product has no users."});
                        setAlertVisible(true);
                    }
                    setStatus({success: true});
                }).catch(error => {
                    setErrors({submit: error.message});
                    setStatus({success: false});
                    setAlertVisible(true);
                });
        }
    }

    const deleteProduct = (props) => {
        const { productBeanIndex, deleteAll } = props;
        const newProductsBeans = [];

        setErrors({});
        productBeans.map((productBean, index) => {
            if (!deleteAll && index !== productBeanIndex) newProductsBeans.push(productBean);
        });
        axios.post('/web/ui/bulkUser/updateProductConfig', newProductsBeans, {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId, subscriberType: fileSubscriberType}})
            .then(response => {
                updateLocalUserProducts(response.data.bulkUserProducts, response.data.multiAuthority);
                setStatus({success: true});
                //setSubmitting(false);
            }).catch(error => {
                setErrors({submit: error.message});
                setStatus({success: false});
                //setSubmitting(false);
            });
    }

    const updateLocalUserProducts = (products, multiAuthority) => {
        if(multiAuthority) {
            setErrors({products: 'Important: You have selected products which are accessed via different URLs so users will have multiple accounts'});
            setAlertVisible(true);
        }
        setProductBeans(products ? products : []);
    }

    const updateUserProducts = () => {
        setSubmitting(true);
        axios.get('/web/ui/bulkUser/updateUserProducts', {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId, subscriberType: fileSubscriberType}}).then(response => {
            setStatus({success: true});
            //setSubmitting(false);
            if (onNext) {
                onNext();
            }
        }).catch(error => {
            setErrors({submit: error.message});
            setStatus({success: false});
            setSubmitting(false);
        });
    }

    if (enquiry.bulkUserUpdateId) {
        useEffect(() => {
            findBulkUsers(enquiry.bulkUserUpdateId, fileSubscriberType);
            findClassNames(enquiry.bulkUserUpdateId);
            findSubjects(enquiry.bulkUserUpdateId);
            findYears(enquiry.bulkUserUpdateId);
            findProductSubjects();
            findRegions();
            findProducts(enquiry.bulkUserUpdateId, fileSubscriberType);
            loadProducts(enquiry.bulkUserUpdateId, fileSubscriberType);
        }, []);
    } else {
        setErrors({file: 'Invalid bulkUserUpdateId'});
    }

    useEffect(() => {
        findProducts(enquiry.bulkUserUpdateId, fileSubscriberType, regionId, productSubject);
    }, [productSubject, regionId]);

    return (
        <Card>
            <CardHeader title={enquiryType.title + " - " + stepName}  titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
            <Divider/>
            <CardContent>
                {errors.products && isAlertVisible && (
                    <Box mb={3}>
                        <Alert
                            onClose={() => setAlertVisible(false)}
                            severity="info"
                        >
                            {errors.products}
                        </Alert>
                    </Box>
                )}
                {isSubmitting  || isLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        my={5}
                    >
                        <CircularProgress/>
                    </Box>
                ) : (
                    <Box
                         id="productInputs"
                    >
                        {((classNames && classNames.length > 0) || (subjects && subjects.length > 0) || (years && years.length > 0)) && (
                            <Typography variant="body2" color="textSecondary">
                                Assign resources by:
                            </Typography>
                        )}
                        {fileSubscriberType == 'STUDENT' && ((classNames && classNames.length > 0) || (subjects && subjects.length > 0) || (years && years.length > 0)) && (
                            <Box>
                                <Box m={0}>
                                    <FormControl variant="outlined">
                                        <RadioGroup row aria-label="resourceAssignmentType" name="resourceAssignmentType" value={resourceAssignmentType} onChange={handleChangeResourceAssignmentType}>
                                            {classNames && classNames.length > 0 && (
                                                <FormControlLabel value="CLASS" control={<Radio />} label="Class" />
                                            )}
                                            {subjects && subjects.length > 0 && (
                                                <FormControlLabel value="SUBJECT" control={<Radio />} label="Subject" />
                                            )}
                                            {years && years.length > 0 && (
                                                <FormControlLabel value="SCHOOL_YEAR" control={<Radio />} label="Year Level" />
                                            )}
                                            <FormControlLabel value="ALL" control={<Radio />} label="Assign to All" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                        )}
                        <Box>
                            <Box
                                display="flex"
                                alignItems="center"
                            >
                                {fileSubscriberType === 'STUDENT' && resourceAssignmentType == 'CLASS' && classNames && classNames.length > 0 && classNames[0] &&(
                                    <TextField
                                        label="Class"
                                        name="class"
                                        select
                                        SelectProps={{ native: true }}
                                        variant="outlined"
                                        style={{marginLeft: 5, marginRight: 5}}
                                    >
                                        <option value="-">-</option>
                                        {classNames.map((className) => (
                                            <option
                                                key={className}
                                                value={className}
                                            >
                                                {className}
                                            </option>
                                        ))}
                                    </TextField>
                                )}
                                {fileSubscriberType=== 'STUDENT' && resourceAssignmentType == 'SUBJECT' && subjects && subjects.length > 0 && subjects[0] &&(
                                    <TextField
                                        label="Subject"
                                        name="subject"
                                        select
                                        SelectProps={{ native: true }}
                                        variant="outlined"
                                        style={{marginLeft: 5, marginRight: 5}}
                                    >
                                        <option value="-">-</option>
                                        {subjects.map((subjects) => (
                                            <option
                                                key={subjects}
                                                value={subjects}
                                            >
                                                {subjects}
                                            </option>
                                        ))}
                                    </TextField>
                                )}
                                {fileSubscriberType === 'STUDENT' && resourceAssignmentType == 'SCHOOL_YEAR' && years && years.length > 0 && years[0] &&(
                                    <TextField
                                        label="Year"
                                        name="year"
                                        select
                                        SelectProps={{ native: true }}
                                        variant="outlined"
                                        style={{marginLeft: 5, marginRight: 5}}
                                    >
                                        <option value="-">-</option>
                                        {years.map((year) => (
                                            <option
                                                key={year}
                                                value={year}
                                            >
                                                {year}
                                            </option>
                                        ))}
                                    </TextField>
                                )}
                            </Box>
                            <Box m={2} display="flex" alignItems="center" flexDirection="column">
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                    style={{fontWeight: 'bold'}}
                                >
                                    * Only assign resources your school has purchased or booklisted *
                                </Typography>
                            </Box>
                            <Box  m={1}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{marginBottom: 10}}
                                >
                                    Find resources by:
                                </Typography>
                                <Grid container direction="row" alignItems="flex-end" spacing={2}>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel id="region-label">State: </InputLabel>
                                            <Select labelId="region-label"
                                                    id="regionId"
                                                    name="regionId"
                                                    value={regionId ? regionId : -1}
                                                    onChange={handleChangeRegion}
                                                    label="State"
                                            >
                                                <MenuItem value={-1}>All</MenuItem>
                                                {regions.map((regionOption) => (
                                                    <MenuItem value={regionOption.regionId}
                                                              key={regionOption.regionId}>{regionOption.regionDesc}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel id="subject-label">Subject: </InputLabel>
                                            <Select labelId="subject-label"
                                                    id="productSubject"
                                                    name="productSubject"
                                                    value={productSubject ? productSubject : -1}
                                                    onChange={handleChangeProductSubject}
                                                    label="Subject"
                                            >
                                                <MenuItem value={-1}>All</MenuItem>
                                                {productSubjects.map((productSubject) => (
                                                    <MenuItem value={productSubject}
                                                              key={productSubject}>{productSubject}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {products == undefined || products.length == 0 &&
                                        <Grid item>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                display="inline"
                                            >
                                                No resources have been found
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            <Box
                                m={1}
                                display="flex"
                                alignItems="center"
                            >
                                <Autocomplete
                                    name="product"
                                    options={products}
                                    disableCloseOnSelect
                                    multiple
                                    limitTags={4}
                                    filterOptions={filterOptions}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: 800 }}
                                    onChange={handleChangeProduct}
                                    value={selectedProducts}
                                    disabled={(products == undefined || products.length == 0) && (selectedProducts == undefined || selectedProducts.length == 0)}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'normal'}}>
                                                <div>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    <span style={{fontSize: '14px'}}>{option.name}</span>
                                                </div>
                                                {option.isbn && (
                                                    <div><span style={{fontSize: '12px'}}>{option.regionId ? 'Region: ' + option.regionName + ', ': ''} ISBN: {option.isbn}</span></div>
                                                )}
                                            </div>
                                        </li>
                                    )}
                                    renderInput={(params) => {
                                        return <TextField {...params} label="Resource" variant="outlined" />
                                    }}
                                />
                                <Button
                                    onClick={addProduct}
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    style={{marginLeft: '8px'}}
                                    disabled={selectedProducts == undefined || selectedProducts.length == 0 }
                                >
                                    Add
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            mt={2}
                            display="flex"
                            justifyContent="center"
                        >
                            {productBeans && productBeans.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Resource</TableCell>
                                            <TableCell>Class</TableCell>
                                            {fileSubscriberType === 'STUDENT' && (
                                                <>
                                                    <TableCell>Subject</TableCell>
                                                    <TableCell>Year</TableCell>
                                                </>
                                            )}
                                            <TableCell>Accounts</TableCell>
                                            <TableCell style={{padding:0}}>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => deleteProduct({deleteAll: true})}
                                                    size="large">
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productBeans.map((productBean, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {productBean.productName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {productBean.className && (
                                                            <>{productBean.className}</>
                                                        )}
                                                    </TableCell>
                                                    {fileSubscriberType=== 'STUDENT' && (
                                                        <>
                                                            <TableCell>
                                                                {productBean.subject && (
                                                                    <>{productBean.subject}</>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {productBean.schoolYear && (
                                                                    <>{productBean.schoolYear}</>
                                                                )}
                                                            </TableCell>
                                                        </>
                                                    )}
                                                    <TableCell>
                                                        {productBean.totalUsers && (
                                                            <>{productBean.totalUsers}</>
                                                        )}
                                                    </TableCell>
                                                    <TableCell style={{padding:0}}>
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteProduct({productBeanIndex: index})}
                                                            size="large">
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            ) : (
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    No resources have been assigned
                                </Typography>
                            )}
                        </Box>
                        <Box
                            mt={6}
                            display="flex"
                        >
                            {onBack && (
                                <Button
                                    onClick={() => {setLoading(true); onBack();}}
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Previous
                                </Button>
                            )}
                            <Box flexGrow={1}/>
                            <Button
                                onClick={updateUserProducts}
                                color="secondary"
                                variant="contained"
                                size="large"
                                disabled={productBeans == undefined || productBeans.length == 0}
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

AssignProducts.propTypes = {
    enquiryType: PropTypes.object,
    stepName: PropTypes.string,
    enquiry: PropTypes.object,
    onNext: PropTypes.func,
    onBack: PropTypes.func
};

export default AssignProducts;
