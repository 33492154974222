import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { UserProvider } from './context/UserContext';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import App from './App';

enableES5();


ReactDOM.render(
    <UserProvider>
        <GoogleReCaptchaProvider reCaptchaKey="6LeEQ8sZAAAAAGi7WIDI40-BGQ2kv-E86xYtvMje">
            <App />
        </GoogleReCaptchaProvider>
    </UserProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
