import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TopBar from './TopBar';
import StickyFooter from "./StickyFooter";
import { useTheme } from '@mui/material/styles';

function DashboardLayout({ children }) {
  const theme = useTheme();
  const styles = {
    root: {
      backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto'
    }
  };
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div style={{...styles.root}}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <div style={{...styles.wrapper}}>
        <div style={{...styles.contentContainer}}>
          <div style={{...styles.content}}>
            {children}
            <StickyFooter/>
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
