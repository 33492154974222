import React, {useContext} from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Typography,
  colors,
} from '@mui/material';
import Page from '../../../components/Page';
import LoginForm from './LoginForm';
import UserContext from "../../../context/UserContext";
import { useTheme } from '@mui/material/styles';

function LoginView() {
  const navigate = useNavigate();
  const {user, saveUser} = useContext(UserContext);
  const theme = useTheme();
  const styles = {
    root: {
      justifyContent: 'center',
      backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      minHeight: '100%',
      flexDirection: 'column',
      paddingBottom: 80,
      paddingTop: 80
    },
    backButton: {
      marginLeft: theme.spacing(2)
    },
    card: {
      overflow: 'visible',
      display: 'flex',
      position: 'relative',
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%',
        width: '50%'
      }
    },
    content: {
      padding: theme.spacing(8, 4, 3, 4)
    },
    icon: {
      backgroundColor: colors.green[500],
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      position: 'absolute',
      top: -32,
      left: theme.spacing(3),
      height: 64,
      width: 64
    },
    media: {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      padding: theme.spacing(3),
      color: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('lg')]: {
        display: 'none'
      }
    }
  };

  const handleSubmitSuccess = (user) => {
    saveUser(user);
    navigate.push('/app');
  };

  return (
    <Page
      style={{...styles.root}}
      title="Login"
    >
      <Container maxWidth="md">
        <Card style={{...styles.card}}>
          <CardContent style={{...styles.content}}>
            <Typography
              variant="h2"
              color="textPrimary"
            >
              Cambridge Service Desk
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Sign in required for testing
            </Typography>
            <Box mt={3}>
              <LoginForm onSubmitSuccess={handleSubmitSuccess} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default LoginView;
