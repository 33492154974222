import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Paper,
    FormHelperText,
    Typography,
    Radio,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
} from '@mui/material';
import UserContext from '../../../context/UserContext';

function EnquiryType({enquiryType,
                         enquiryTypes,
                         className,
                         onBack,
                         onNext,
                         onChangeEnquiryType
                     }) {
    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const {user, saveUser} = useContext(UserContext);

    const handleChange = (newEnquiryType) => {
        const newEnquiry = enquiryTypes[newEnquiryType];
        if (onChangeEnquiryType) {
            onChangeEnquiryType(newEnquiry);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setSubmitting(true);

        if (onNext) {
            onNext();
            setSubmitting(false);
        }
    };

    return (
        <Card>
            <CardHeader title={enquiryType.title} titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
            <Divider/>
            <CardContent>
                {isSubmitting ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        my={5}
                    >
                        <CircularProgress/>
                    </Box>
                ) : (
                    <form
                        onSubmit={handleSubmit}
                    >
                        <Box mt={2}>
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Select from one of the enquiry options below.
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            {Object.values(enquiryTypes).map((typeOption) => (
                                <Paper
                                    display="flex"
                                    alignItems="flex-start"
                                    p={2}
                                    mb={2}
                                    component={Box}
                                    elevation={enquiryType.enquiryType === typeOption.enquiryType ? 10 : 1}
                                    key={typeOption.enquiryType}
                                >
                                    <Radio
                                        checked={enquiryType.enquiryType === typeOption.enquiryType}
                                        onClick={() => handleChange(typeOption.enquiryType)}
                                    />
                                    <Box ml={2}>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            color="textPrimary"
                                        >
                                            {typeOption.title}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="textPrimary"
                                        >
                                            {typeOption.description}
                                        </Typography>
                                    </Box>
                                </Paper>
                            ))}
                        </Box>
                        {error && (
                            <Box mt={2}>
                                <FormHelperText error>
                                    {error}
                                </FormHelperText>
                            </Box>
                        )}
                        <Box
                            mt={6}
                            display="flex"
                        >
                            {onBack && (
                                <Button
                                    disabled={true}
                                    onClick={onBack}
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Previous
                                </Button>
                            )}
                            <Box flexGrow={1}/>
                            <Button
                                color="secondary"
                                disabled={isSubmitting}
                                type="submit"
                                variant="contained"
                                size="large"
                            >
                                Next
                            </Button>
                        </Box>
                    </form>
                )}
            </CardContent>
        </Card>
    );
}

EnquiryType.propTypes = {
    enquiryType: PropTypes.object,
    enquiryTypes: PropTypes.object,
    className: PropTypes.string,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onChangeEnquiryType: PropTypes.func
};

export default EnquiryType;
