import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import SplashScreen from './SplashScreen';
import authService from '../services/authService';
import UserContext from "../context/UserContext";

function Auth({ children }) {
  const [isLoading, setLoading] = useState(true);
  const {user, saveUser} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect( () => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: ( () => {
          authService.setUserSession(null);
          saveUser({});
          //navigate.push('/sessionExpired');
        })
      });

      authService.loadUserSession().then(response => {
        if(response.loggedIn) {
          saveUser(response);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }).catch(error => {
        saveUser({});
        setLoading(false);
      });
    };

    initAuth();
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
