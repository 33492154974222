import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {
    Box,
    Button,
    TextField,
    Card,
    CardContent,
    CircularProgress,
} from '@mui/material';
import { Alert } from '@mui/material';
import authService from "../../../services/authService";

function LoginForm({className, onSubmitSuccess, ...rest}) {
    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values, {
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                authService.loginWithEmailAndPassword(values.email, values.password).then(response => {
                    if(response.userSession.loggedIn) {
                        setStatus({success: true});
                        setSubmitting(false);
                        if (onSubmitSuccess) {
                            onSubmitSuccess(response.userSession);
                        }
                    } else {
                        setStatus({success: false});
                        setErrors({submit: 'Invalid email or password'});
                        setSubmitting(false);
                    }
                }).catch(error => {
                    const message = (error.response && error.response.data.message) || 'Error logging in';

                    setStatus({success: false});
                    setErrors({submit: message});
                    setSubmitting(false);
                });

                    /*const requestOptions = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(values)
                    };
                    fetch('/web/ui/auth/public/login', requestOptions)
                        .then(response => {
                            if (response.ok) {
                                return response.json();
                            } else {
                                return false;
                            }
                        }).then(data => {
                            console.log('response', data);
                            if(data.userLoginResult.loggedIn) {
                                setStatus({success: true});
                                setSubmitting(false);
                                if (onSubmitSuccess) {
                                    onSubmitSuccess(data.userSession);
                                }
                            } else {
                                setStatus({success: false});
                                setErrors({submit: 'Invalid email or password'});
                                setSubmitting(false);
                            }
                    }).catch (error => {
                        const message = (error.response && error.response.data.message) || 'Error logging in';

                        setStatus({success: false});
                        setErrors({submit: message});
                        setSubmitting(false);
                    });*/
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
              }) => (
                <Card>
                    <CardContent>
                        {errors.submit && (
                            <Box mb={3}>
                                <Alert
                                    onClose={() => setAlertVisible(false)}
                                    severity="info"
                                >
                                    {errors.submit}
                                </Alert>
                            </Box>
                        )}
                        {isSubmitting ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                my={5}
                            >
                                <CircularProgress/>
                            </Box>
                        ) : (
                            <form
                                noValidate
                                className={className}
                                onSubmit={handleSubmit}
                                {...rest}
                            >
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    autoFocus
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                />
                                <Box mt={2}>
                                    <Button
                                        color="secondary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Log In
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </CardContent>
                </Card>
            )}
        </Formik>
    );
}

LoginForm.propTypes = {
    className: PropTypes.string,
    onSubmitSuccess: PropTypes.func
};

LoginForm.defaultProps = {
    onSubmitSuccess: () => {
    }
};

export default LoginForm;
