import React from 'react';
import {colors, Grid, Link, Container, Typography} from "@mui/material";
import { useTheme } from '@mui/material/styles';

export default function StickyFooter() {
    const theme = useTheme();
    const styles = {
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        main: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(2),
        },
        footer: {
            padding: theme.spacing(3, 2),
            marginTop: 'auto',
            backgroundColor: theme.palette.primary.main,
            color: colors.common.white
        },
        footerLink: {
            color: colors.common.white,
            padding: '5px 5px',
            "&:hover": {
                color: '#00b5cc'
            }
        },
    };

    return (
        <div style={{...styles.root}} >
            <footer style={{...styles.footer}}>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        <Typography>
                            {new Date().getFullYear()} {' Cambridge University Press'}
                        </Typography>
                        <Typography>
                            <Link underline="none" style={{...styles.footerLink}} href="https://www.cambridge.org/go/privacy_policy">
                                Privacy Policy
                            </Link>
                            <Link underline="none" style={{...styles.footerLink}} href="https://www.cambridge.org/go/terms">
                                Terms of Use
                            </Link>
                            <Link underline="none" style={{...styles.footerLink}} href="https://www.cambridge.org/go/accessibility_statement">
                                Accessibility Statement
                            </Link>
                        </Typography>
                    </Grid>
                </Container>
            </footer>
        </div>
    );
}