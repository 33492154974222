import React, {
  Suspense,
  Fragment
} from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useNavigate } from 'react-router';
import DashboardLayout from './layouts/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import Error404View from "./views/pages/Error404View";
import LoginView from './views/auth/LoginView';
import Error401View from "./views/pages/Error401View";
import EnquiryView from "./views/service/EnquiryView";

const routesConfig = [
  {
    exact: true,
    path: '/service/404',
    element: Error404View
  },
  {
    exact: true,
    path: '/service/login',
    element: LoginView
  },
  {
    exact: true,
    path: '/service/sessionExpired',
    element: Error401View
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/service/app/service/start',
    element: EnquiryView
  },
  {
    exact: false,
    path: '*',
    layout: DashboardLayout,
    element: () => {return <Navigate to="/service/app/service/start" />}
  }
];

const renderRoutes = (routes) => (routes ? (
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element || Fragment;

        return (
            <Route
                key={i}
                path={route.path}
                exact={route.exact}
                element={<Guard>
                  <Layout>
                    <Element />
                  </Layout>
                </Guard>}
            />
        );
      })}
    </Routes>
) : null);

function ServiceDeskRoutes() {
  return renderRoutes(routesConfig);
}

export default ServiceDeskRoutes;
