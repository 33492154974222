import React from 'react';
import {Box, Typography} from "@mui/material";

function Logo(props) {
  return (
    <div>
        <Typography
            className="logo-text"
        >
            Cambridge
        </Typography>
        <img
            alt="Logo"
            src="/serviceDesk/static/go_logo.svg"
            style={ {width:'30px', verticalAlign: 'middle', margin: '2px 4px'}}
            {...props}
        />
    </div>
  );
}

export default Logo;
