import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar, SvgIcon, Typography } from '@mui/material';
import Logo from '../../../components/Logo';
import { useTheme } from '@mui/material/styles';

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const theme = useTheme();
  const styles = {
    appBar: {
      zIndex: theme.zIndex.drawer + 100,
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    },
    toolbar: {
      minHeight: 64
    }
  };

  return (
    <AppBar className={className} styles={{...styles.appBar}}
      color={'primary'}
      {...rest}
    >
      <Toolbar style={{...styles.toolbar}}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box
          ml={2}
        >
          <Typography
              variant="h5"
          >
            Service Desk
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
