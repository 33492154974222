import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
    CardHeader,
    Divider,
    CardContent,
    Link,
    Card,
    Avatar,
} from '@mui/material';
import {Star as StarIcon} from 'react-feather';
import {Link as RouterLink} from "react-router-dom";


function EnquiryConfirmation({
                            enquiryType,
                            enquiry,
                            onBack,
                            ...rest
                        }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    return (
        <Card>
            <CardHeader title={enquiryType.title} titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
            <Divider/>
            <CardContent>
                <Box
                    maxWidth={600}
                    mx="auto"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <Avatar>
                            <StarIcon />
                        </Avatar>
                    </Box>
                    <Box mt={2}>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            align="center"
                        >
                            Your enquiry has been submitted.
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            align="center"
                            paragraph={true}
                        >
                            Your Service Desk ticket number is <Box component="span" fontWeight="fontWeightBold">{enquiry.ticketId}</Box>.
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            align="center"
                            paragraph={true}
                        >
                            You will also receive an email from our Service Desk with your ticket number for reference. If you do not receive this, please check your spam/junk mail.
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            align="center"
                            paragraph={true}
                        >
                            It is also advisable to alert your IT department to <Link href="https://cambridgehelp.zendesk.com/hc/en-us/articles/360039073652-How-can-my-school-unblock-emails-and-domains-required-for-my-Cambridge-digital-resources-?utm_source=servicedesk&utm_medium=website&utm_campaign=whitelist" underline="always" target="_blank"><Box component="span" fontWeight="fontWeightBold">this article</Box></Link> regarding whitelisted domains and addresses to ensure all communication from our service desk is received.
                        </Typography>
                    </Box>
                    <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                    >
                    </Box>
                    <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            component={RouterLink}
                            to="/app/service"
                        >
                            Another Enquiry
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

EnquiryConfirmation.propTypes = {
    enquiryType: PropTypes.object,
    enquiry: PropTypes.object,
    onBack: PropTypes.func
};

export default EnquiryConfirmation;
