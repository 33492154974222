import React, {useEffect, useState, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  colors,
  CircularProgress, StepConnector,
} from '@mui/material';
import {
  User as UserIcon,
  Briefcase as BriefcaseIcon,
  File as FileIcon
} from 'react-feather';
import Page from '../../../components/Page';
import EnquiryType from './EnquiryType';
import EnquiryDetails from './EnquiryDetails';
import EnquiryConfirmation from './EnquiryConfirmation';
import ContactDetails from "./ContactDetails";
import UploadConfirmation from './UploadConfirmation';
import UploadUsersFile from "./UploadUsersFile";
import ReviewUploadFile from "./ReviewUploadFile";
import SchoolInformation from "./SchoolInformation";
import axios from "../../../utils/axios";
import BulkUploadOptions from "./BulkUploadOptions";
import AssignProducts from "./AssignProducts";
import { styled } from "@mui/system";
import {useTheme} from "@mui/material/styles";

const enquirySteps = [
  {
    label: 'Select Enquiry',
    icon: UserIcon,
    disablePreviousStep: true,
    disableNextStep: false,
    component: EnquiryType
  },
  {
    label: 'Contact information and Enquiry',
    icon: BriefcaseIcon,
    disablePreviousStep: true,
    disableNextStep: false,
    component: EnquiryDetails
  },
  {
    label: 'Confirmation',
    icon: FileIcon,
    disablePreviousStep: true,
    disableNextStep: false,
    component: EnquiryConfirmation
  }
];

const provisioningSteps = [
  {
    label: 'Select Enquiry',
    icon: UserIcon,
    disablePreviousStep: true,
    disableNextStep: false,
    component: EnquiryType
  },
  {
    label: 'Contact Information',
    icon: UserIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    component: ContactDetails
  },
  {
    label: 'School Information',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    component: SchoolInformation
  },
  {
    label: 'Account Options',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    component: BulkUploadOptions
  },
  {
    label: 'Upload Accounts',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    component: UploadUsersFile
  },
  {
    label: 'Assign Resources',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    component: AssignProducts
  },
  {
    label: 'Review',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    nextStepFinal: true,
    component: ReviewUploadFile,
  },
  {
    label: 'Confirmation',
    icon: FileIcon,
    disablePreviousStep: true,
    disableNextStep: true,
    component: UploadConfirmation
  }
];

const studentTeacherProvisioningSteps = [
  {
    label: 'Select Enquiry',
    icon: UserIcon,
    disablePreviousStep: true,
    disableNextStep: false,
    component: EnquiryType
  },
  {
    label: 'Contact Information',
    icon: UserIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    component: ContactDetails
  },
  {
    label: 'School Information',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    component: SchoolInformation
  },
  {
    label: 'Account Options',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    component: BulkUploadOptions
  },
  {
    label: 'Upload Students',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    subscriberType: 'STUDENT',
    component: UploadUsersFile
  },
  {
    label: 'Assign Student Resources',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    subscriberType: 'STUDENT',
    component: AssignProducts
  },
  {
    label: 'Review Students',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    subscriberType: 'STUDENT',
    component: ReviewUploadFile,
  },
  {
    label: 'Upload Teachers',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    subscriberType: 'TEACHER',
    component: UploadUsersFile
  },
  {
    label: 'Assign Teacher Resources',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    subscriberType: 'TEACHER',
    component: AssignProducts
  },
  {
    label: 'Review Teachers',
    icon: BriefcaseIcon,
    disablePreviousStep: false,
    disableNextStep: false,
    nextStepFinal: true,
    subscriberType: 'TEACHER',
    component: ReviewUploadFile,
  },
  {
    label: 'Confirmation',
    icon: FileIcon,
    disablePreviousStep: true,
    disableNextStep: true,
    component: UploadConfirmation
  }
];

const enquiryTypes = {
  ACCOUNT_PROVISIONING: {
    key: 'accountUpload',
    enquiryType: 'ACCOUNT_PROVISIONING',
    title: 'Account and resource upload',
    description: 'I want to set up accounts, products and classes or, add new resources to existing accounts',
    steps: provisioningSteps,
    studentTeacherSteps: studentTeacherProvisioningSteps
  },
  PASSWORD: {
    key: 'passwordEnquiry',
    enquiryType: 'PASSWORD',
    title: ' Login Issue',
    description: 'I am having a problem accessing my account',
    steps: enquirySteps
  },
  EMAIL_VERIFICATION: {
    key: 'emailEnquiry',
    enquiryType: 'EMAIL_VERIFICATION',
    title: 'Email verification',
    description: 'I am having trouble verifying my account or I have not received a verification email',
    steps: enquirySteps
  },
  ACTIVATION_CODE: {
    key: 'activationCodeEnquiry',
    enquiryType: 'ACTIVATION_CODE',
    title: 'Activation code enquiry',
    description: 'I am having trouble with my activation code',
    steps: enquirySteps
  },
  ERRATA: {
    key: 'textbookMistake',
    enquiryType: 'ERRATA',
    title: 'Textbook error',
    description: 'I have found an error or an omission in one of your textbooks',
    steps: enquirySteps
  },
  GENERAL: {
    key: 'generalEnquiry',
    enquiryType: 'GENERAL',
    title: 'General enquiry',
    description: 'I have an enquiry for your digital support team',
    steps: enquirySteps
  }
};

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  // Styles for the vertical orientation
  marginLeft: '19px',
  padding: 0,

  // Styles for the line
  '.MuiStepConnector-line': {
    borderColor: theme.palette.divider,
  },
}));

function CustomStepIcon({ active, completed, icon }) {
  const Icon = UserIcon;
  const theme = useTheme();
  const styles = {
    active: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: theme.shadows[10]
    },
    completed: {
      backgroundColor: theme.palette.secondary.main,
    }
  }

  return (
    <Avatar
        style={{
          ...(active && styles.active),
          ...(completed && styles.completed),
        }}
    >
      <Icon size="20" />
    </Avatar>
  );
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number
};

const initialEnquiryState = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  activationCode: '',
  reactivationCode: '',
  isbn: '',
  contentLocation: '',
  enquiryText: '',
  acceptTerms: false,
  trial: false,
  sso: false,
  clearClasses: false,
  bulkUserUpdateId: undefined,
  enquiryType: enquiryTypes.ACCOUNT_PROVISIONING.enquiryType,
  importUserType: 'SCHOOL',
  importType: 'UPLOAD',
  schoolEmail: '',
  booksellerEmail: '',
  salesEmail: '',
  schoolName: '',
  enquiryEmail: '',
  salesUserId: -1,
  booksellerSchoolId: undefined,
  regionId: -1,
  schoolId: undefined,
  studentFileName: undefined,
  studentFileSize: 0,
  teacherFileName: undefined,
  teacherFileSize: 0,
  subscriberType: 'STUDENT'
}

function EnquiryView() {
  const scrollBoxRef = useRef(null)
  const [isLoading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [steps, setSteps] = useState(provisioningSteps);
  const [enquiryType, setEnquiryType] = useState(enquiryTypes.ACCOUNT_PROVISIONING);
  const [subscriberType, setSubscriberType] = useState(initialEnquiryState.subscriberType);
  const [enquiry, setEnquiry] = useState({...initialEnquiryState});
  const [ticketId, setTicketId] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    avatar: {
      backgroundColor: colors.red[600]
    }
  };

  useEffect(() => {
    loadEnquiry();
  }, []);

  useEffect(() => {
    if (!isLoading && location.search !== '') {
      navigate(location.pathname, {replace: true});
      changeEnquiryTypeFromUrl(new URLSearchParams(location.search));
      handleNext();
      location.search = '';
    }
  }, [isLoading]);

  const scrollToTop = () => {
    scrollBoxRef.current.scrollIntoView()
  };

  const handleNext = () => {
    if(enquiryType.enquiryType == 'ACCOUNT_PROVISIONING' && activeStep > 0) {
      scrollToTop();
      setEnquiry((prevEnquiry => {
        prevEnquiry.activeStep = activeStep + 1;
        axios.post('/web/ui/bulkUser/storeEnquiry', prevEnquiry)
            .then(response => {
              if(response.data) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              } else {
                setActiveStep(0);
                setEnquiry({...initialEnquiryState});
              }
            }).catch(error => {
              console.log('error', error);
            });
        return prevEnquiry;
      }));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setEnquiry(prevEnquiry => {
        prevEnquiry.activeStep = activeStep + 1;
        return prevEnquiry;
      });
    }
  };

  const handleNextFinal = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setEnquiry((prevEnquiry => {
      prevEnquiry.activeStep = activeStep + 1;
      return prevEnquiry;
    }));
  };

  const handleBack = () => {
    if(enquiryType.enquiryType == 'ACCOUNT_PROVISIONING') {
      setEnquiry((prevEnquiry => {
        prevEnquiry.activeStep = activeStep - 1;
        return prevEnquiry;
      }));

      axios.post('/web/ui/bulkUser/storeEnquiry', enquiry)
          .then(response => {
            if (response.data) {
              setActiveStep((prevActiveStep) => prevActiveStep > 0 ? prevActiveStep - 1: 0);
            } else {
              setActiveStep(0);
              setEnquiry({...initialEnquiryState});
            }
          }).catch(error => {
            console.log('error', error);
          });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep > 0 ? prevActiveStep - 1: 0);
      setEnquiry((prevEnquiry => {
        prevEnquiry.activeStep = activeStep - 1;
        return prevEnquiry;
      }));
    }
  };

  const loadEnquiry = () => {
    axios.get('/web/ui/bulkUser/findSessionEnquiry')
        .then(response => {
          if(response.data && response.data.activeStep != undefined && response.data.enquiryType != undefined) {
            const newEnquiryType = enquiryTypes[response.data.enquiryType];

            if(response.data.activeStep > 0 && response.data.activeStep < (newEnquiryType.steps.length - 1) && response.data.importStatus != 'COMPLETED') {
              setEnquiry(response.data);
              setEnquiryType(newEnquiryType);
              setSteps(newEnquiryType.steps);
              setSubscriberType(response.data.subscriberType);

              setActiveStep(response.data.activeStep);
            } else {
              setActiveStep(0);
              setEnquiry({...initialEnquiryState});
              setSubscriberType(initialEnquiryState.subscriberType);
            }
            setLoading(false);
          } else {
            setActiveStep(0);
            setEnquiry({...initialEnquiryState});
            setLoading(false);
          }
        }).catch(error => {
          setActiveStep(0);
          setEnquiry({...initialEnquiryState});
          setLoading(false);
        });
  };

  const handleChangeSubscriberType = (newSubscriberType) => {
    setSubscriberType(newSubscriberType);
  };

  const changeEnquiryTypeFromUrl = (params) => {
    let newEnquiryType = params.get('enquiryType') !== null ? enquiryTypes[params.get('enquiryType')] : undefined;

    if (newEnquiryType !== undefined) {
      setEnquiryType(newEnquiryType);
      setEnquiry((prevEnquiry => {
        prevEnquiry.activeStep = 0;
        params.forEach((value, key) => {
          prevEnquiry[key] = value;
        });
        return prevEnquiry;
      }));

      setSteps(newEnquiryType.steps);
      setActiveStep(0);
    }
  };

  const handleChangeEnquiryType = (newEnquiryType) => {
    setEnquiryType(newEnquiryType);
    setEnquiry((prevEnquiry => {
      prevEnquiry.enquiryType = newEnquiryType.enquiryType;
      prevEnquiry.activeStep = 0;
      return prevEnquiry;
    }));

    setSteps(newEnquiryType.steps);
    setActiveStep(0);
  };

  const handleChangeEnquiry = (updatedEnquiry) => {
    setEnquiry(prevEnquiry => {
      /* console.log('studentFileName', prevEnquiry.studentFileName, updatedEnquiry.studentFileName);
      console.log('teacherFileName', prevEnquiry.teacherFileName, updatedEnquiry.teacherFileName);
      console.log('subscriberType', prevEnquiry.subscriberType, updatedEnquiry.subscriberType); */

      if(updatedEnquiry.subscriberType == 'TEACHER' && prevEnquiry.studentFileName !== undefined) {
        updatedEnquiry.studentFileName = undefined;
      }

      if(updatedEnquiry.subscriberType == 'STUDENT' && prevEnquiry.teacherFileName !== undefined) {
        updatedEnquiry.teacherFileName = undefined;
      }

      return {...prevEnquiry, ...updatedEnquiry};
    });
  };

  const handleSubmitTicket = (newTicketId) => {
    setTicketId(newTicketId);
  };

  const handleComplete = () => {
    setCompleted(true);
    setActiveStep(0);
    setEnquiry({...initialEnquiryState});
  };

  const handleReset = () => {
    setLoading(true);
    axios.post('/web/ui/bulkUser/cancelBulkUserEnquiry')
        .then(response => {
          setActiveStep(0);
          setEnquiry({...initialEnquiryState});
          setEnquiryType(enquiryTypes.ACCOUNT_PROVISIONING);
          setSteps(provisioningSteps)
          setLoading(false);
        }).catch(error => {
          console.log('error', error);
          setActiveStep(0);
          setEnquiry({...initialEnquiryState});
          setEnquiryType(enquiryTypes.ACCOUNT_PROVISIONING);
          setSteps(provisioningSteps)
          setLoading(false);
        });
  };

  useEffect(() => {
    if(subscriberType === undefined && enquiryType.studentTeacherSteps != undefined) {
      setSteps(enquiryType.studentTeacherSteps);
    } else {
      setSteps(enquiryType.steps);
    }
  }, [subscriberType]);

  const StepComponent = steps[activeStep].component;

  return (
    <Box style={{minHeight: '80%'}} ref={scrollBoxRef}>
      {isLoading ? (
        <Box
            display="flex"
            justifyContent="center"
            my={5}
        >
          <CircularProgress/>
        </Box>
      ) : (
        <Page
          style={{...styles.root}}
          title="Service Desk"
        >
          <Container maxWidth="lg">
            <Paper>
              <Grid container>
                <Grid
                    item
                    xs={12}
                    md={2.2}
                    p={2}
                >
                  <Stepper
                      activeStep={activeStep}
                      connector={<CustomStepConnector />}
                      orientation="vertical"
                      component={Box}
                      bgcolor="transparent"
                  >
                    {steps.map((step) => (
                        <Step key={step.label}>
                          <StepLabel StepIconComponent={CustomStepIcon}>
                            {step.label}
                          </StepLabel>
                        </Step>
                    ))}
                  </Stepper>
                  <Box style={{textAlign: 'center'}} mb={2}>
                      <Button
                          color="secondary"
                          variant="contained"
                          size="medium"
                          onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={9.8}
                >
                  <Box p={3}>
                    <StepComponent fileSubscriberType={steps[activeStep].subscriberType === undefined ? subscriberType: steps[activeStep].subscriberType} enquiryType={enquiryType} stepName={steps[activeStep].label} enquiry={enquiry} onBack={handleBack} onNext={steps[activeStep].nextStepFinal ? handleNextFinal:  handleNext} onChangeEnquiryType={handleChangeEnquiryType} onChangeEnquiry={handleChangeEnquiry} enquiryTypes={enquiryTypes} onChangeSubscriberType={setSubscriberType} nextStepFinal={steps[activeStep].nextStepFinal}/>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Page>
      )}
    </Box>
  );
}

export default EnquiryView;
