import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// import jssPreset from '@mui/material/styles/jssPreset';
// import StylesProvider from '@mui/material/styles/StylesProvider';
import ScrollReset from './components/ScrollReset';
import { createConfigTheme } from './theme';
import ServiceDeskRoutes from './ServiceDeskRoutes';
import Auth from "./components/Auth";

const history = createBrowserHistory();
// const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createConfigTheme()}>
        {/*<StylesProvider jss={jss}>*/}
          <SnackbarProvider maxSnack={1}>
              <BrowserRouter basename="/" history={history}>
                <Auth>
                  <ScrollReset />
                  <ServiceDeskRoutes />
                </Auth>
              </BrowserRouter>
          </SnackbarProvider>
        {/*</StylesProvider>*/}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
