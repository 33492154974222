import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Backdrop,
} from '@mui/material';
import {Formik, useFormikContext} from 'formik';
import {Upload as UploadIcon} from 'react-feather';
import { Alert } from '@mui/material';
import axios from "../../../utils/axios";
import bytesToSize from "../../../utils/bytesToSize";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {useTheme} from "@mui/material/styles";

const initialUser = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    classNames: '',
    subject: '',
    schoolYear: ''
};

function UploadUsersFile({enquiryType,
                             enquiry,
                             fileSubscriberType,
                             stepName,
                             onBack,
                             onNext,
                             onChangeEnquiry
                        }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [file, setFile] = useState(undefined);
    const [bulkUsers, setBulkUsers] = useState([]);
    const [individualBulkUsers, setIndividualBulkUsers] = useState([]);
    const [columnHeadValues, setColumnHeadValues] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isLoadingBulkUsers, setLoadingBulkUsers] = useState(false);
    const [isValidColumns, setValidColumns] = useState(false);
    const [errors, setErrors] = useState({});
    const [importType, setImportType] = useState(enquiry.importType);
    const [isValidAddUser, setValidAddUser] = useState(false);
    const [isValidUpdateUser, setValidUpdateUser] = useState(false);
    const [editBulkUser, setEditBulkUser] = useState(undefined);
    const [yearOptions, setYearOptions] = useState(['Kinder', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const [openSpreadsheet, setOpenSpreadsheet] = React.useState(false);
    const theme = useTheme();

    const handleFileSelect = (event) => {
        if(event.target.files.length > 0 && event.target.files[0]) {
            const updatedFile = event.target.files[0];

            setFile(updatedFile);
            setSubmitting(true);
            setErrors({});

            const formData = new FormData()
            formData.append('file', updatedFile);
            formData.append('bulkUserUpdateId', enquiry.bulkUserUpdateId);
            formData.append('subscriberType', fileSubscriberType);
            axios.post('/web/ui/bulkUser/uploadBulkUserFile', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then(response => {
                    if(response.data == 'true') {
                        if(fileSubscriberType == 'STUDENT') {
                            enquiry.studentFileName = updatedFile.name;
                            enquiry.studentFileSize = updatedFile.size;
                        } else {
                            enquiry.teacherFileName = updatedFile.name;
                            enquiry.teacherFileSize = updatedFile.size;
                        }

                        if (onChangeEnquiry) {
                            onChangeEnquiry(enquiry);
                        }
                        extractColumns(enquiry.bulkUserUpdateId);
                    } else {
                        setErrors({file: response.data});

                        setSubmitting(false);
                        setAlertVisible(true);
                    }
                }).catch(error => {
                    setErrors({file: error.message});
                    setSubmitting(false);
                    setAlertVisible(true);
            });
        } else {
            setErrors({file: 'Please select a file'});
            setAlertVisible(true);
        }
    };

    const processBulkUserUpdate = () => {
        setSubmitting(true);
        if(importType == 'UPLOAD') {
            axios.get('/web/ui/bulkUser/processBulkUserUpdate', {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId, subscriberType: fileSubscriberType}})
                .then(response => {
                    if(onNext) {
                        onNext();
                    }
                }).catch(error => {
                setErrors({submit: error.message});
                setSubmitting(false);
                setAlertVisible(true);
            });
        } else {
            if(onNext) {
                onNext();
            }
        }

    }

    const updateColumnSequences = (newColumnValues) => {
        let newColumns = [];
        columns.map((column, index) => {
            column.matched = false;
        });
        for (let i = 0; i < newColumnValues.length; ++i) {
            const columnValue = newColumnValues[i];
            let newColumn;
            columns.map((column, index) => {
                if (columnValue === column.columnType) {
                    newColumn = Object.assign({}, column, {
                        columnId: i,
                        columnSeq: i,
                        matched: true
                    });
                    newColumns.push(newColumn);
                    column.matched = true;
                }
            });
        }
        // setSubmitting(true);
        axios.post('/web/ui/bulkUser/updateColumnSequences', newColumns, {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId, subscriberType: fileSubscriberType}})
            .then(response => {
                setValidColumns(response.data);
            }).catch(error => {
                setErrors({submit: error.message});
                setAlertVisible(true);
            });
    }

    const extractColumns = (bulkUserUpdateId) => {
        setLoadingBulkUsers(true);
        axios.get('/web/ui/bulkUser/extractColumns', {params: {bulkUserUpdateId: bulkUserUpdateId, subscriberType: fileSubscriberType}})
            .then(response => {
                setBulkUsers(response.data);
                setSubmitting(false);
                setLoadingBulkUsers(false);
            }).catch(error => {
                setBulkUsers([]);
                setErrors({submit: error.message});
                setSubmitting(false);
                setLoadingBulkUsers(false);
                setAlertVisible(true);
            });
    }

    const loadColumns = (bulkUserUpdateId, subscriberType) => {
        axios.get('/web/ui/bulkUser/columns', {params: {subscriberType: subscriberType}})
            .then(response => {
                if(response.data) {
                    setColumns(response.data);
                    setDefaultColumnHeadValues(response.data);
                }
            }).catch(error => {
                console.log('Error', error);
                setColumns([]);
                setLoading(false)
            });
    }

    const UploadTableHead = (props) => {
        const {columns, bulkUsers} = props;
        const handleChangeColumn = (event, column) => {
            const target = event.target;
            const tableHeads = document.getElementById('tableHeads');
            const tableHeadsInputs = tableHeads.getElementsByTagName('input');
            let newColumnValues = [];
            for (let index = 0; index < tableHeadsInputs.length; ++index) {
                const tableHeadsInput = tableHeadsInputs[index];
                if ((index + 1) === column.columnId) {
                    newColumnValues[index] = target.value;
                } else if (target.value != 'EMPTY' && target.value === tableHeadsInput.getAttribute('value')) {
                    newColumnValues[index] = 'EMPTY';
                } else {
                    newColumnValues[index] = columnHeadValues[index];
                }
            }
            setColumnHeadValues(newColumnValues);
            updateColumnSequences(newColumnValues);
        }

        const buildSelectMenu = (props) => {
            const {column} = props;
            return (
                <FormControl size="small">
                    <Select
                        onChange={(event) => handleChangeColumn(event, column)}
                        value={columnHeadValues[column.columnId - 1]}
                    >
                    <MenuItem key="0" value="EMPTY">Select</MenuItem>
                        {Object.values(columns).map((column, index) => (
                        <MenuItem key={column.columnId} value={column.columnType}>{column.columnName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        }
            return (
                <TableHead>
                    <TableRow id="tableHeads">
                        {Object.values(columns).map((column, index) => {
                            if (index < bulkUsers[0].length) {
                                return (
                                    <TableCell key={index}>
                                        {buildSelectMenu({column: column})}
                                    </TableCell>
                                );
                            }
                        })}
                    </TableRow>
                </TableHead>
            )
        }

    const setDefaultColumnHeadValues = (columnsData) => {
        let newValues = [];
        {Object.values(columnsData).map((column1, index1) => {
            let menuValue;
            Object.values(columnsData).map((column2, index2) => {
                if (column2.matched && column2.columnSeq >= 0 && index1  === column2.columnSeq) menuValue = column2.columnType;
                if (menuValue !== undefined && newValues.length === index1) {
                    newValues.push(menuValue);
                }
            });
            if (menuValue === undefined) newValues.push('EMPTY');
        })}
        setColumnHeadValues(newValues);
    }

    const loadBulkUserConfig = (bulkUserUpdateId) => {
        axios.get('/web/ui/bulkUser/findBulkUserConfig', {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId, subscriberType: fileSubscriberType}})
            .then(response => {
                if(response.data && enquiry.bulkUserUpdateId && ((enquiry.studentFileName && fileSubscriberType == 'STUDENT') || (enquiry.teacherFileName && fileSubscriberType == 'TEACHER'))) {
                    extractColumns(enquiry.bulkUserUpdateId);

                    if(response.data.columns) {
                        for (let updatedColumn of response.data.columns) {
                            if(updatedColumn.matched) {
                                const column = columns.find(column => column.columnType == updatedColumn.columnType);

                                if(column != undefined) {
                                    column.matched = updatedColumn.matched;
                                    column.columnSeq = updatedColumn.columnSeq;
                                }
                            }
                        }
                        setDefaultColumnHeadValues(columns);
                        setValidColumns(response.data.validColumns);
                    }

                }
                setLoading(false);
            }).catch(error => {
                console.log('Error', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (enquiry.bulkUserUpdateId) {
            loadColumns(enquiry.bulkUserUpdateId, fileSubscriberType);
            findBulkUsers(enquiry.bulkUserUpdateId, fileSubscriberType);
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (enquiry.bulkUserUpdateId) {
            loadBulkUserConfig(enquiry.bulkUserUpdateId);
        }
    }, [columns]);

    if (!enquiry.bulkUserUpdateId) {
        setErrors({file: 'Invalid bulkUserUpdateId'});
        setAlertVisible(true);
    }

    const handleChangeInsertAccountType = (event, importType) => {
        setImportType(importType);
        enquiry.importType =  importType;
        if (onChangeEnquiry) {
            onChangeEnquiry(enquiry);
        }
    }

    const validationShape = {
        firstName: Yup.string().min(2, 'Must be at least 2 characters').max(200).required('Required'),
        lastName: Yup.string().min(2, 'Must be at least 2 characters').max(200).required('Required'),
        email: Yup.string().email().max(200).required('Required')
    };
    if (fileSubscriberType === "STUDENT") {
        validationShape.classNames = Yup.string().min(3, 'Must be at least 3 characters').max(200).required('Required');
    }

    const handleAddUser = (formValues) => {
        axios.post('/web/ui/bulkUser/addBulkUser', {subscriberType: fileSubscriberType, ...formValues}, {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId}}).then(response => {
            if (response.data) {
                setIndividualBulkUsers(response.data);
            } else {
                setErrors({submit: 'Error contact details'});
            }
        }).catch(error => {
            setErrors({submit: error.message});
        });
    }

    const handleUpdateUser = (formValues) => {
        axios.post('/web/ui/bulkUser/updateBulkUser', formValues, {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId}}).then(response => {
            if (response.data) {
                setIndividualBulkUsers(response.data);
                setEditBulkUser(undefined);
            } else {
                setErrors({submit: 'Error contact details'});
            }
        }).catch(error => {
            setErrors({submit: error.message});
        });
    }

    const findBulkUsers = (bulkUserUpdateId, subscriberType) => {
        axios.get('/web/ui/bulkUser/findBulkUsers', {params: {bulkUserUpdateId: bulkUserUpdateId, valid: true, subscriberType: subscriberType}})
            .then(response => {
                setIndividualBulkUsers(response.data);
            }).catch(error => {
                setBulkUsers([]);
                setLoading(false);
            });
    }

    const editUser = (bulkUserId) => {
        const editUser = Object.assign({}, initialUser, {
            ...individualBulkUsers.find((bulkUser) => bulkUser.bulkUserId === bulkUserId)
        });
        setEditBulkUser(editUser);
    }

    const deleteUser = (bulkUserId) => {
        setSubmitting(true);
        axios.post('/web/ui/bulkUser/deleteBulkUser', {}, {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId, bulkUserId: bulkUserId, subscriberType: fileSubscriberType}})
            .then(response => {
                setIndividualBulkUsers(response.data);
                setSubmitting(false);
            }).catch(error => {
            setErrors({submit: error.message});
            setSubmitting(false);
        });
    }

    const deleteAllUsers = () => {
        setSubmitting(true);
        axios.post('/web/ui/bulkUser/deleteAllBulkUsers', {}, {params: {bulkUserUpdateId: enquiry.bulkUserUpdateId, subscriberType: fileSubscriberType}})
            .then(response => {
                setIndividualBulkUsers(response.data);
                setSubmitting(false);
            }).catch(error => {
            setErrors({submit: error.message});
            setSubmitting(false);
        });
    }

    const UpdateValidAddUser = () => {
        const {values} = useFormikContext();
        const schema = Yup.object().shape(validationShape);
        React.useEffect(() => {
            schema.isValid(values).then(function (valid) {
                setValidAddUser(valid);
            });
        }, [values]);
        return null;
    };

    const UpdateValidUpdateUser = () => {
        const {values} = useFormikContext();
        const schema = Yup.object().shape(validationShape);
        React.useEffect(() => {
            schema.isValid(values).then(function (valid) {
                setValidUpdateUser(valid);
            });
        }, [values]);
        return null;
    }

    return (
        <Card>
            <CardHeader title={enquiryType.title + " - " + stepName} titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
            <Divider/>
            <CardContent>
                {errors.file && isAlertVisible && (
                    <Box mb={3}>
                        <Alert
                            onClose={() => setAlertVisible(false)}
                            severity="error"
                        >
                            {errors.file}
                        </Alert>
                    </Box>
                )}
                {isSubmitting || isLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        my={5}
                    >
                        <CircularProgress/>
                    </Box>
                ) : (
                <Box mx="auto">
                    <Box mt={2}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            How would you like to add users?
                        </Typography>
                        <FormControl variant="outlined">
                            <RadioGroup row aria-label="uploadType" name="uploadType" value={importType} onChange={handleChangeInsertAccountType}>
                                <FormControlLabel value="UPLOAD" control={<Radio />} label="Upload spreadsheet" />
                                <FormControlLabel value="INDIVIDUAL" control={<Radio />} label={'Add individual ' + (fileSubscriberType == 'STUDENT' ? 'students' : 'teachers')} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {importType === "UPLOAD" && (
                        <>
                            <Box mt={2}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="span"
                                    style={{margin: '2px 5px'}}
                                >
                                    Select a CSV spreadsheet to upload
                                </Typography>
                                <input
                                    style={{ display: 'none' }}
                                    id="file"
                                    name="file"
                                    type="file"
                                    accept=".csv"
                                    onChange={handleFileSelect}
                                />
                                <label htmlFor="file">
                                    <Button size="medium" variant="contained" component="span" color="secondary">
                                        <SvgIcon
                                            fontSize="small"
                                        >
                                            <UploadIcon />
                                        </SvgIcon>
                                        &nbsp;Upload {fileSubscriberType == 'STUDENT' ? 'Students' : 'Teachers'}
                                    </Button>
                                </label>
                                <IconButton
                                    aria-label="spreadsheet help"
                                    onClick={() => setOpenSpreadsheet(true)}
                                    size="large">
                                    <HelpIcon fontSize="large" style={{color: '#375719'}} />
                                </IconButton>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="span"
                                    style={{marginLeft: '-10px', color: '#375719'}}
                                >
                                    What should my spreadsheet look like?
                                </Typography>
                                <Backdrop styles={{zIndex: (theme.zIndex.drawer + 1), color: '#fff'}} open={openSpreadsheet} onClick={() => {
                                    setOpenSpreadsheet(false);
                                }}>
                                    <div style={{position: 'relative', textAlign: 'center'}}>
                                        <img
                                            alt="Spreadsheet"
                                            src="/serviceDesk/static/images/service_desk_spreadsheet.png"
                                            style={{width: '70%'}}
                                        />
                                        <div style={{padding: '5px 5px', borderRadius: '15px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'rgba(0, 0, 0, 0.7)'}}>
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                style={{color: '#fff'}}
                                            >
                                                Here is an example of what your spreadsheet should look like. Columns are not required to be in any particular order as you will be able to allocate column names once your spreadsheet has been uploaded.
                                            </Typography>

                                        </div>
                                    </div>
                                </Backdrop>
                            </Box>
                            {((enquiry.studentFileName && fileSubscriberType == 'STUDENT') || (enquiry.teacherFileName && fileSubscriberType == 'TEACHER')) && (
                                <>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        <Box style={{verticalAlign: "middle"}} display="flex">
                                            <InsertDriveFileIcon style={{verticalAlign: 'bottom'}}/>
                                            <Typography
                                                color="textPrimary"
                                                variant="body2"
                                            >
                                                {enquiry.fileName}
                                            </Typography>
                                            <Typography
                                                color="textPrimary"
                                                variant="body2"
                                            >
                                                &nbsp;{fileSubscriberType == 'STUDENT' ? enquiry.studentFileName + ' (' + bytesToSize(enquiry.studentFileSize) + ')' : enquiry.teacherFileName + ' (' + bytesToSize(enquiry.teacherFileSize) + ')'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box m={1}
                                         justifyContent="center">

                                        <Typography
                                            color="textSecondary"
                                            variant="body2"
                                        >
                                            Please select column headers. As a minimum, you need to include 'First Name', 'Last Name', 'Email' and at least one of 'Class' or 'Subject' or 'Year level'. By including these columns, it will make it easier for you to allocate resources in the next step.
                                        </Typography>
                                    </Box>
                                    <Box m={2}>
                                        {
                                            columns.map((column, index) => {
                                                return <Box key={index} m={1}>
                                                    {column.matched && (
                                                        <Box>
                                                            <Alert severity="success" style={{margin: 0, padding: '2px'}}>
                                                                {column.columnName} has been selected
                                                            </Alert>
                                                        </Box>
                                                    )}

                                                    {!column.matched && column.required && (
                                                        <Box>
                                                            <Alert severity="error" style={{margin: 0, padding: '2px'}}>
                                                                {column.columnName} is required
                                                            </Alert>
                                                        </Box>
                                                    )}

                                                    {!column.matched && !column.required && column.columnType != 'USERNAME' && column.columnType != 'PASSWORD' && (
                                                        <Box>
                                                            <Alert severity="info" style={{margin: 0, padding: '2px'}}>
                                                                {column.columnName} is optional but will make resource allocation easier for you
                                                            </Alert>
                                                        </Box>
                                                    )}
                                                </Box>;
                                            })
                                        }
                                    </Box>
                                </>
                                )}
                            {bulkUsers != undefined && bulkUsers.length > 0 && (
                                <Box
                                    mt={1}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table"  size="small">
                                            <UploadTableHead columns={columns} bulkUsers={bulkUsers}  />
                                            <TableBody>
                                                {bulkUsers.map((bulkUser, index1) => (
                                                    <TableRow key={index1}>
                                                        {bulkUser.map((item, index2) => {
                                                            return (
                                                                <TableCell key={index2}>
                                                                    {item}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                            {isLoadingBulkUsers && (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    my={5}
                                >
                                    <CircularProgress/>
                                </Box>
                            )}
                        </>
                    )}
                    {importType === "INDIVIDUAL" && (
                        <>
                            <Formik
                                initialValues={initialUser}
                                validationSchema={Yup.object().shape(validationShape)}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    resetForm,
                                    touched,
                                    values
                                }) => (
                                    <form id="individualUserForm">
                                        <UpdateValidAddUser />
                                        <Box mt={2}>
                                            <Grid container
                                                  spacing={2}
                                            >
                                                <Grid item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField fullWidth
                                                        error={Boolean(touched.firstName && errors.firstName)}
                                                        helperText={touched.firstName && errors.firstName}
                                                        label="First Name"
                                                        name="firstName"
                                                        required={true}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.firstName}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField fullWidth
                                                        error={Boolean(touched.lastName && errors.lastName)}
                                                        helperText={touched.lastName && errors.lastName}
                                                        label="Last Name"
                                                        name="lastName"
                                                        required={true}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.lastName}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box mt={2}>
                                            <Grid container
                                                  spacing={2}
                                            >
                                                <Grid item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField fullWidth
                                                        error={Boolean(touched.email && errors.email)}
                                                        helperText={touched.email && errors.email}
                                                        label="Email"
                                                        name="email"
                                                        required={true}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField fullWidth
                                                        error={Boolean(touched.username && errors.username)}
                                                        helperText={touched.username && errors.username}
                                                        label="Username"
                                                        name="username"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.username}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box mt={2}>
                                            <Grid container
                                                spacing={2}
                                            >
                                                <Grid item xs>
                                                    <TextField fullWidth
                                                        error={Boolean(touched.classNames && errors.classNames)}
                                                        helperText={touched.classNames && errors.classNames}
                                                        label="Class"
                                                        name="classNames"
                                                        required={true}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.classNames}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField fullWidth
                                                        error={Boolean(touched.subject && errors.subject)}
                                                        helperText={touched.subject && errors.subject}
                                                        label="Subject"
                                                        name="subject"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.subject}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel htmlFor="outlined-schoolYear-native">Year&nbsp;Level</InputLabel>
                                                        <Select native fullWidth
                                                            error={Boolean(touched.schoolYear && errors.schoolYear)}
                                                            // helperText={touched.schoolYear && errors.schoolYear}
                                                            name="schoolYear"
                                                            label="Year Level"
                                                            inputProps={{
                                                                name: 'schoolYear',
                                                                id: 'outlined-schoolYear-native',
                                                            }}
                                                            value={values.schoolYear}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        >
                                                            <option value=""></option>
                                                            {yearOptions.map((year, index) => (
                                                                <option key={index} value={year}>
                                                                    {year}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs>
                                                    <Button
                                                        onClick={() => {handleAddUser(values); resetForm(initialUser);}}
                                                        size="large"
                                                        color="secondary"
                                                        variant="contained"
                                                        disabled={!isValidAddUser}
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                            {individualBulkUsers != undefined && individualBulkUsers.length > 0 && (
                                <Box
                                    mt={4}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow key={0}>
                                                    {columns.map((column, index) => {
                                                        if (column.columnValue !== 'password') {
                                                            return (
                                                                <TableCell key={index}>
                                                                    {column.columnName}
                                                                </TableCell>
                                                            )
                                                        }
                                                    })}
                                                    <TableCell style={{padding:0}}></TableCell>
                                                    <TableCell style={{padding:0}}>
                                                        <IconButton aria-label="delete" onClick={deleteAllUsers} size="large">
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {individualBulkUsers.map((bulkUser, index1) => (
                                                    <TableRow key={index1}>
                                                        {columns.map((column, index2) => {
                                                            if (column.columnValue !== 'password') {
                                                                let rtnString = '';
                                                                for (const [key, value] of Object.entries(bulkUser)) {
                                                                    if (column.columnValue === key) {
                                                                        rtnString = value;
                                                                        break;
                                                                    }
                                                                }
                                                                return (
                                                                    <TableCell key={index2}>
                                                                        {rtnString}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        })}
                                                        <TableCell style={{padding:0}}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => editUser(bulkUser.bulkUserId)}
                                                                size="large">
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell style={{padding:0}}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => deleteUser(bulkUser.bulkUserId)}
                                                                size="large">
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                        </>
                    )}
                    <Box
                        mt={6}
                        display="flex"
                    >
                        {onBack && (
                            <Button
                                onClick={() => {setLoading(true); onBack();}}
                                size="large"
                                color="secondary"
                                variant="contained"
                            >
                                Previous
                            </Button>
                        )}
                        <Box flexGrow={1}/>
                        <Button
                            onClick={processBulkUserUpdate}
                            color="secondary"
                            variant="contained"
                            size="large"
                            disabled={(importType === "UPLOAD" && !isValidColumns) || (importType === "INDIVIDUAL" && individualBulkUsers.length < 1)}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>)}
            </CardContent>
            <Dialog
                open={editBulkUser !== undefined}
                onClose={() => {
                    setEditBulkUser(undefined);
                }}
            >
                <Formik
                    initialValues={editBulkUser}
                    validationSchema={Yup.object().shape(validationShape)}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        resetForm,
                        touched,
                        values
                    }) => (
                        <>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Edit values
                                </DialogContentText>
                                <form style={{margin:'16px 0 8px'}}>
                                    <UpdateValidUpdateUser />
                                    <Grid container
                                          spacing={2}
                                    >
                                        <Grid item
                                              md={6}
                                              xs={12}
                                        >
                                            <TextField fullWidth
                                                       error={Boolean(touched.firstName && errors.firstName)}
                                                       helperText={touched.firstName && errors.firstName}
                                                       label="First Name"
                                                       name="firstName"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       value={values.firstName}
                                                       variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item
                                              md={6}
                                              xs={12}
                                        >
                                            <TextField fullWidth
                                                       error={Boolean(touched.lastName && errors.lastName)}
                                                       helperText={touched.lastName && errors.lastName}
                                                       label="Last Name"
                                                       name="lastName"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       value={values.lastName}
                                                       variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                          spacing={2}
                                    >
                                        <Grid item
                                              md={6}
                                              xs={12}
                                        >
                                            <TextField fullWidth
                                                       error={Boolean(touched.email && errors.email)}
                                                       helperText={touched.email && errors.email}
                                                       label="Email"
                                                       name="email"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       value={values.email}
                                                       variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item
                                              md={6}
                                              xs={12}
                                        >
                                            <TextField fullWidth
                                                       error={Boolean(touched.username && errors.username)}
                                                       helperText={touched.username && errors.username}
                                                       label="Username"
                                                       name="username"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       value={values.username}
                                                       variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                          spacing={2}
                                    >
                                        <Grid item xs>
                                            <TextField fullWidth
                                                       error={Boolean(touched.classNames && errors.classNames)}
                                                       helperText={touched.classNames && errors.classNames}
                                                       label="Class"
                                                       name="classNames"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       value={values.classNames}
                                                       variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField fullWidth
                                                       error={Boolean(touched.subject && errors.subject)}
                                                       helperText={touched.subject && errors.subject}
                                                       label="Subject"
                                                       name="subject"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       value={values.subject}
                                                       variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-schoolYear-native">Year&nbsp;Level</InputLabel>
                                                <Select native fullWidth
                                                        error={Boolean(touched.schoolYear && errors.schoolYear)}
                                                    // helperText={touched.schoolYear && errors.schoolYear}
                                                        name="schoolYear"
                                                        label="Year Level"
                                                        inputProps={{
                                                            name: 'schoolYear',
                                                            id: 'outlined-schoolYear-native',
                                                        }}
                                                        value={values.schoolYear}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                >
                                                    <option value=""></option>
                                                    {yearOptions.map((year, index) => (
                                                        <option key={index} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                onClick={() => handleUpdateUser(values)}
                                size="large"
                                disabled={!isValidUpdateUser}
                                >
                                Update
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </Card>
    );
}

UploadUsersFile.propTypes = {
    enquiryType: PropTypes.object,
    stepName: PropTypes.string,
    enquiry: PropTypes.object,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onChangeEnquiry: PropTypes.func
};

export default UploadUsersFile;
